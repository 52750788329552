<div class="address-input">
  <h3 class="address-input__address-title">Address</h3>
  <form #form [formGroup]="addressForm" class="address-input__form">
    <!-- Address -->
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Address</mat-label>
        <input [formControl]="address1Control"
               [readonly]="disabled"
               [value]="address1Control.value"
               id="address-input__address" matInput placeholder="Address" required type="text">
        <mat-error *ngIf="address1Control.touched && address1Control.hasError('required')">
          This field is required
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Suburb / locality_name -->
    <mat-form-field appearance="outline">
      <mat-label>Suburb</mat-label>
      <input [formControl]="suburbControl" [readonly]="disabled" [value]="suburbControl.value"
             id="address-input__suburb"
             matInput
             placeholder="Suburb" required type="text">
      <mat-error *ngIf="suburbControl.hasError('required')">
        This field is required
      </mat-error>
    </mat-form-field>
    <div class="address-input__container">
      <!-- State / state_territory -->
      <mat-form-field appearance="outline" class="address-input__state">
        <mat-label>State</mat-label>
        <input [formControl]="stateControl" [readonly]="disabled" [value]="stateControl.value" id="address-input__state"
               matInput
               placeholder="State" required type="text">
        <mat-error *ngIf="stateControl.hasError('required')">
          This field is required
        </mat-error>
      </mat-form-field>
      <!-- Postcode -->
      <mat-form-field appearance="outline" class="address-input__postcode">
        <mat-label>Postcode</mat-label>
        <input [formControl]="postcodeControl" [readonly]="disabled" [value]="postcodeControl.value"
               id="address-input__postcode" matInput placeholder="Postcode" required type="text">
        <mat-error *ngIf="postcodeControl.hasError('required')">
          This field is required
        </mat-error>
      </mat-form-field>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>Country</mat-label>
      <mat-select [disabled]="disabled" [formControl]="countryControl"
                  [typeaheadDebounceInterval]="countryInput.focused ? 1500 :  0"
                  id="address-input__country" required>
        <mat-option [disabled]="true">
          <input #countryInput="matInput" [formControl]="countrySearch" autocomplete="off" matInput
                 placeholder="Search" />
        </mat-option>
        <mat-option *ngFor="let option of filterCountries" [value]="option.value"
                    id="address-input__country-option-{{option.value|lowdash}}">
          {{ option.value }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="countryControl.hasError('required')">
        This field is required
      </mat-error>
    </mat-form-field>
  </form>
</div>
