export abstract class NumericRange {
  translate?: (value: number, label: LabelType) => string;

  abstract min: number;
  abstract max: number;
  abstract step: number;

  getRange(): number[] {
    const length = (this.max - this.min) / this.step + 1;
    return Array.from({ length }, (_, i) => this.min + i * this.step);
  }
}

export enum LabelType {
  Min = 0,
  Max = 1,
}
