import { Pipe, PipeTransform } from '@angular/core';

/**
 * Usage: {{nbr | ordinal}}
 */
@Pipe({
  name: 'ordinal',
})
export class OrdinalPipe implements PipeTransform {
  transform(value: number): string {
    if (value === undefined || value === null || !value) {
      return '';
    }

    return value + ['st', 'nd', 'rd'][((((value + 90) % 100) - 10) % 10) - 1] || 'th';
  }
}
