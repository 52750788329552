import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'projectList',
})
export class ProjectListPipe implements PipeTransform {
  transform(value: any): any {
    switch (value?.length) {
      case null:
      case undefined:
      case 0:
        // In theory, this is never possible. Just to debug
        return 'Unknown';
      case 1:
        return `${value[0]}`;
      case 2:
        return `${value[0]} and ${value[1]}`;
      default:
        return `${value[0]}, ${value[1]} and ${value.length - 2} more`;
    }
  }
}
