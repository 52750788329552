import { Pipe, PipeTransform } from '@angular/core';
import parsePhoneNumber from 'libphonenumber-js';

@Pipe({
  name: 'phoneMask',
})
export class PhoneNumberMaskPipe implements PipeTransform {
  transform(phone: string): string {
    if (!phone) {
      return phone;
    }
    const phoneNumber = parsePhoneNumber(phone);
    if (phoneNumber) {
      return phoneNumber.formatInternational();
    }
    return phone;
  }
}
