import { NumericRange } from './numeric-range';

export class PriceRange extends NumericRange {
  static readonly MIN = 50000;
  static readonly MAX = 5000000;
  static readonly STEP = 25000;
  private config: PriceRangeConfig | null;

  min = PriceRange.MIN;
  max = PriceRange.MAX;
  step = PriceRange.STEP;

  constructor(config?: PriceRangeConfig) {
    super();
    this.config = config ?? null;
  }

  getRange(): number[] {
    const range: number[] = [];
    let value = this.min;

    if (this.config) {
      while (value <= this.max) {
        range.push(value);
        if (value < this.config.after) {
          value += this.step;
        } else {
          value += this.config.step;
        }
      }
      return range;
    }

    while (value <= this.max) {
      range.push(value);
      value += this.step;
    }
    return range;
  }
}

export interface PriceRangeConfig {
  after: 1000000; // after this value, step will be as defined below
  step: 250000;
}
