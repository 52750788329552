import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import {
  AllInvitationsQuery,
  AllInvitationsQueryVariables,
  CreateInvitationManyMutation,
  CreateInvitationManyMutationVariables,
  CreateInvitationMutation,
  CreateInvitationMutationVariables,
  GetAgentOnboardingDataQuery,
  GetAgentOnboardingDataQueryVariables,
  GetCurrentUsersByOrganisationIdQuery,
  GetCurrentUsersByOrganisationIdQueryVariables,
  GetInvitationDetailsQuery,
  GetInvitationDetailsQueryVariables,
  GetInvitedUsersByOrganisationIdQuery,
  GetInvitedUsersByOrganisationIdQueryVariables,
  GetOrganisationDetailQuery,
  GetOrganisationDetailQueryVariables,
  GetPendingInvitationsByEmailQuery,
  GetPendingInvitationsByEmailQueryVariables,
  HandleInvitationMutation,
  HandleInvitationMutationVariables,
  IsExistingUserInvitedQuery,
  IsExistingUserInvitedQueryVariables,
  PartnerPortalIsLoginTakenQuery,
  PartnerPortalIsLoginTakenQueryVariables,
  UpdateInvitationByIdMutation,
  UpdateInvitationByIdMutationVariables,
  UpdateInvitationMutation,
  UpdateInvitationMutationVariables,
  UpdateMemberByIdMutation,
  UpdateMemberByIdMutationVariables,
  WithdrawInvitationByIdMutation,
  WithdrawInvitationByIdMutationVariables,
} from '../generated/lib/operations';
import { firstValueFrom, Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';
import {
  Invitation_Bool_Exp,
  Invitation_Insert_Input,
  Invitation_Order_By,
  Invitation_Set_Input,
  Invitation_Type_Enum,
  Member_Role_Enum,
  OrganisationPayload,
  PartnerPortalIsLoginTakenResp,
} from '@skychute/schema';

@Injectable({
  providedIn: 'root',
})
export class InvitationService {
  constructor(private apollo: Apollo) {
  }

  async all(where: Invitation_Bool_Exp): Promise<AllInvitationsQuery['invitation']> {
    const resp = await firstValueFrom(
      this.apollo.query<AllInvitationsQuery, AllInvitationsQueryVariables>({
        query: ALL_INVITATIONS,
        variables: {
          where,
        },
      }),
    );
    return resp.data.invitation;
  }

  async getPendingInvitationsByEmail(
    email: string,
  ): Promise<GetPendingInvitationsByEmailQuery['invitation']> {
    const response = await firstValueFrom(
      this.apollo.query<
        GetPendingInvitationsByEmailQuery,
        GetPendingInvitationsByEmailQueryVariables
      >({
        query: GET_PENDING_INVITATIONS_BY_EMAIL,
        variables: { email },
      }),
    );

    return response?.data.invitation;
  }

  async isExistingUserInvited(invitationId: string): Promise<boolean | undefined> {
    const resp = await firstValueFrom(
      this.apollo.query<IsExistingUserInvitedQuery, IsExistingUserInvitedQueryVariables>({
        query: IS_EXISTING_USER_INVITED,
        variables: {
          invitationId,
        },
      }),
    );

    const invitation = resp.data?.get_invitation;
    if (!invitation) {
      return undefined;
    }

    return resp.data?.get_invitation?.is_existing;
  }

  async getInvitation(invitationId: string): Promise<GetInvitationDetailsQuery['get_invitation']> {
    const resp = await firstValueFrom(
      this.apollo.subscribe<GetInvitationDetailsQuery, GetInvitationDetailsQueryVariables>({
        query: GET_INVITATION_DETAILS,
        variables: {
          invitationId,
        },
      }),
    );
    return resp.data.get_invitation;
  }

  async update(invitationId: string, input: Invitation_Set_Input): Promise<void> {
    await firstValueFrom(
      this.apollo.mutate<UpdateInvitationMutation, UpdateInvitationMutationVariables>({
        mutation: UPDATE_INVITATION,
        variables: {
          invitationId,
          input,
        },
      }),
    );
  }

  //
  // Methods moved from another service
  //
  async handleInvitation(
    invitationId: string,
    isAccepted: boolean,
    organisation?: OrganisationPayload,
  ): Promise<HandleInvitationMutation['handle_invitation']> {
    const resp = await firstValueFrom(
      this.apollo.query<HandleInvitationMutation, HandleInvitationMutationVariables>({
        query: HANDLE_INVITATION,
        variables: {
          invitationId,
          isAccepted,
          organisation,
        },
      }),
    );
    return resp.data.handle_invitation;
  }

  getOrganisationsForInvitation(
    userId: string,
    organizationId: string,
  ): Observable<ApolloQueryResult<GetOrganisationDetailQuery>> {
    return this.apollo.query<GetOrganisationDetailQuery, GetOrganisationDetailQueryVariables>({
      query: GET_ORGANISATION_DETAIL_FOR_INVITATION,
      variables: {
        userId,
        organizationId,
      },
    });
  }

  getCurrentUsers(
    organisationId: string,
    keyword: string,
    roleType: Member_Role_Enum[],
    sorting: Invitation_Order_By,
  ): Observable<ApolloQueryResult<GetCurrentUsersByOrganisationIdQuery>> {
    return this.apollo.query<
      GetCurrentUsersByOrganisationIdQuery,
      GetCurrentUsersByOrganisationIdQueryVariables
    >({
      query: GET_CURRENT_USERS_BY_ORGANISATION_ID,
      variables: {
        organisationId: organisationId,
        keyword: keyword,
        roleType: roleType,
        orderBy: [sorting],
      },
    });
  }

  getInvitedUsers(
    organisationId: string,
    keyword: string,
    roleType: Member_Role_Enum[],
    sorting: Invitation_Order_By,
  ): Observable<ApolloQueryResult<GetInvitedUsersByOrganisationIdQuery>> {
    return this.apollo.query<
      GetInvitedUsersByOrganisationIdQuery,
      GetInvitedUsersByOrganisationIdQueryVariables
    >({
      query: GET_INVITED_USERS_BY_ORGANISATION_ID,
      variables: {
        organisationId: organisationId,
        keyword: keyword,
        roleType: roleType,
        orderBy: [sorting],
      },
    });
  }

  async withdrawInvitation(invitationId: string): Promise<number> {
    const resp = await firstValueFrom(
      this.apollo.mutate<WithdrawInvitationByIdMutation, WithdrawInvitationByIdMutationVariables>({
        mutation: WITHDRAW_INVITATION_BY_ID,
        variables: {
          invitationId,
        },
      }),
    );

    if (resp.errors) {
      throw new Error('Unable to withdraw invitation');
    }
    return resp.data.update_invitation.affected_rows;
  }

  async updateInvitation(invitationId: string, newRole: Member_Role_Enum): Promise<number> {
    const resp = await firstValueFrom(
      this.apollo.mutate<UpdateInvitationByIdMutation, UpdateInvitationByIdMutationVariables>({
        mutation: UPDATE_INVITATION_BY_ID,
        variables: {
          invitationId,
          newRole,
        },
      }),
    );
    if (resp.errors) {
      throw new Error('Unable to update invitation');
    }
    return resp.data.update_invitation.affected_rows;
  }

  async updateMember(userId: string, newRole: Member_Role_Enum): Promise<number> {
    const resp = await firstValueFrom(
      this.apollo.mutate<UpdateMemberByIdMutation, UpdateMemberByIdMutationVariables>({
        mutation: UPDATE_MEMBER_BY_ID,
        variables: {
          userId,
          newRole,
        },
      }),
    );
    if (resp.errors) {
      throw new Error('Unable to update member role');
    }
    return resp.data.update_member.affected_rows;
  }

  async getAgentOnboardingData(
    invitationId: string,
    userId: string,
  ): Promise<GetAgentOnboardingDataQuery> {
    const resp = await firstValueFrom(
      this.apollo.query<GetAgentOnboardingDataQuery, GetAgentOnboardingDataQueryVariables>({
        query: GET_AGENT_ONBOARDING_DATA,
        variables: {
          invitationId,
          userId,
        },
      }),
    );
    return resp.data;
  }

  async create(input: Invitation_Insert_Input): Promise<string> {
    const resp = await firstValueFrom(
      this.apollo.mutate<CreateInvitationMutation, CreateInvitationMutationVariables>({
        mutation: CREATE_INVITATION,
        variables: {
          input,
        },
      }),
    );
    return resp.data.insert_invitation_one.id;
  }

  async createMany(input: Invitation_Insert_Input[]): Promise<void> {
    await firstValueFrom(
      this.apollo.mutate<CreateInvitationManyMutation, CreateInvitationManyMutationVariables>({
        mutation: CREATE_INVITATION_MANY,
        variables: {
          input,
        },
      }),
    );
  }

  /**
   * Returns a PARTNER_PORTAL invitation
   */
  async getPartnerPortalInvitation(): Promise<AllInvitationsQuery['invitation'][0] | null> {
    const invitations = await this.all({
      type: { _eq: Invitation_Type_Enum.PartnerPortal },
    });
    return invitations?.[0] ?? null;
  }

  async partnerPortalIsLoginTaken(login: string, invitationId: string): Promise<PartnerPortalIsLoginTakenResp> {
    const resp = await firstValueFrom(
      this.apollo.query<PartnerPortalIsLoginTakenQuery, PartnerPortalIsLoginTakenQueryVariables>({
        query: PARTNER_PORTAL_IS_LOGIN_TAKEN,
        variables: {
          login,
          invitationId,
        },
      }),
    );
    return resp.data.partner_portal_is_login_taken;
  }
}

// QUERIES
const GET_PENDING_INVITATIONS_BY_EMAIL = gql`
  query getPendingInvitationsByEmail($email: String!) {
    invitation(
      where: { email: { _ilike: $email }, status: { _eq: PENDING } }
      order_by: { created_at: asc }
    ) {
      id
    }
  }
`;

const IS_EXISTING_USER_INVITED = gql`
  query isExistingUserInvited($invitationId: uuid!) {
    get_invitation(invitationId: $invitationId) {
      success
      is_existing
    }
  }
`;

const UPDATE_INVITATION = gql`
  mutation updateInvitation($invitationId: uuid!, $input: invitation_set_input!) {
    update_invitation_by_pk(pk_columns: { id: $invitationId }, _set: $input) {
      id
    }
  }
`;

export const GET_INVITATION_DETAILS = gql`
  query getInvitationDetails($invitationId: uuid!) {
    get_invitation(invitationId: $invitationId) {
      success
      message
      email
      invitation_status
      invitation_type
      inviter_organisation_name
      inviter_organisation_id
      invitation_role
      inviter_firstname
      inviter_lastname
      invitee_firstname
      invitee_lastname
      invitee_phone_number
      allocation_projects
    }
  }
`;

//
// From another service
//
const GET_INVITATION_BY_ID = gql`
  query getInvitationById($invitationId: uuid!) {
    get_invitation(invitationId: $invitationId) {
      success
      message
      invitation_id
      invitation_status
      invitation_type
      inviter_organisation_name
      inviter_organisation_id
      invitation_role
    }
  }
`;

const HANDLE_INVITATION = gql`
  mutation handleInvitation(
    $invitationId: String!
    $isAccepted: Boolean!
    $organisation: OrganisationPayload
  ) {
    handle_invitation(
      invitationId: $invitationId
      isAccepted: $isAccepted
      organisation: $organisation
    ) {
      success
      error
    }
  }
`;

const GET_ORGANISATION_DETAIL_FOR_INVITATION = gql`
  query getOrganisationDetail($userId: uuid, $organizationId: uuid) {
    organisation(
      where: { id: { _eq: $organizationId }, members: { user_id: { _eq: $userId } } }
      limit: 20
    ) {
      id
      name
      members(where: { user_id: { _eq: $userId } }) {
        role
      }
    }
  }
`;

const GET_CURRENT_USERS_BY_ORGANISATION_ID = gql`
  query getCurrentUsersByOrganisationId(
    $organisationId: uuid!
    $keyword: String!
    $roleType: [member_role_enum!]
    $orderBy: [user_order_by!]
  ) {
    user(
      where: {
        _and: [
          { members: { organisation_id: { _eq: $organisationId }, role: { _in: $roleType } } }
          {
            _or: [
              { email: { _ilike: $keyword } }
              { first_name: { _ilike: $keyword } }
              { last_name: { _ilike: $keyword } }
            ]
          }
        ]
      }
      order_by: $orderBy
    ) {
      id
      email
      first_name
      middle_name
      last_name
      members(where: { organisation_id: { _eq: $organisationId } }) {
        role
      }
    }
  }
`;

const GET_INVITED_USERS_BY_ORGANISATION_ID = gql`
  query getInvitedUsersByOrganisationId(
    $organisationId: uuid!
    $keyword: String!
    $roleType: [member_role_enum!]
    $orderBy: [invitation_order_by!]
  ) {
    invitation(
      where: {
        organisation_id: { _eq: $organisationId }
        email: { _ilike: $keyword }
        role: { _in: $roleType }
      }
      order_by: $orderBy
    ) {
      id
      email
      status
      role
      created_at
      inviter {
        first_name
        last_name
      }
    }
  }
`;

const WITHDRAW_INVITATION_BY_ID = gql`
  mutation withdrawInvitationById($invitationId: uuid!) {
    update_invitation(where: { id: { _eq: $invitationId } }, _set: { status: WITHDRAWN }) {
      affected_rows
    }
  }
`;

const UPDATE_INVITATION_BY_ID = gql`
  mutation updateInvitationById($invitationId: uuid!, $newRole: member_role_enum) {
    update_invitation(where: { id: { _eq: $invitationId } }, _set: { role: $newRole }) {
      affected_rows
    }
  }
`;

const UPDATE_MEMBER_BY_ID = gql`
  mutation updateMemberById($userId: uuid!, $newRole: member_role_enum) {
    update_member(where: { user_id: { _eq: $userId } }, _set: { role: $newRole }) {
      affected_rows
    }
  }
`;

const GET_AGENT_ONBOARDING_DATA = gql`
  query getAgentOnboardingData($invitationId: uuid!, $userId: uuid!) {
    organisation(where: { members: { user_id: { _eq: $userId } } }) {
      id
      name
    }
    get_invitation(invitationId: $invitationId) {
      success
      message
      invitation_status
      inviter_organisation_name
      invitee_organisation_name
      inviter_firstname
      inviter_lastname
      allocation_projects
    }
  }
`;

const CREATE_INVITATION = gql`
  mutation createInvitation($input: invitation_insert_input!) {
    insert_invitation_one(object: $input) {
      id
    }
  }
`;

const CREATE_INVITATION_MANY = gql`
  mutation createInvitationMany($input: [invitation_insert_input!]!) {
    insert_invitation(objects: $input) {
      returning {
        id
      }
    }
  }
`;

const ALL_INVITATIONS = gql`
  query allInvitations($where: invitation_bool_exp!) {
    invitation(where: $where) {
      id
    }
  }
`;

const PARTNER_PORTAL_IS_LOGIN_TAKEN = gql`
  query partnerPortalIsLoginTaken($login: String!, $invitationId: String!) {
    partner_portal_is_login_taken(login: $login, invitationId: $invitationId) {
      success
      message
      isTaken
    }
  }
`;
