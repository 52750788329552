<div>
  <div
    class="file-upload-container"
    (drop)="dropFiles($event)"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    (click)="fileSelector.click()"
  >
    <img src="/assets/cloud-upload-icon.svg" alt="upload" width="32" />

    <!-- Desktop -->
    <div class="file-upload-control__file-upload-desktop" >
      <div class="file-upload__title fig-subtitle-1 file-upload-control__mobile--hide">
        Drag & Drop
      </div>
      <div class="file-upload__title fig-subtitle-1 file-upload-control__desktop--hide">
        Upload your files
      </div>
      <div class="file-upload__subtitle fig-caption">
        <span class="file-upload-control__desktop--hide">or </span>
        <span class="file-upload-link-btn file-upload-control__mobile--hide">choose file(s)</span>
        <span class="file-upload-link-btn file-upload-control__desktop--hide">Choose file(s)</span>
      </div>
    </div>

    <!-- Mobile -->
    <div class="file-upload-control__file-upload">
      <div class="file-upload__title fig-subtitle-1">
        Upload your files
      </div>
      <div class="file-upload__subtitle fig-caption"><span class="file-upload-link-btn">Choose file(s)</span></div>
    </div>
  </div>
  <input #fileSelector style="display: none;" (change)="uploadFiles($event)" type="file" [accept]="_accept" />
  <div class="file-upload__accepted fig-caption">
    <div class="file-upload__accept-text">Accepted file formats</div>
    <div>{{ acceptedFileFormats }}</div>
  </div>
</div>
