<div class="svg-loader">
  <div class="svg-loader__loading-bg p-48">
    <svg class="svg-loader__spinner" viewBox="0 0 50 50">
      <circle class="svg-loader__path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
    </svg>
    <div class="svg-loader__title">
      <div matDialogTitle>{{title}}</div>
      <div [innerHTML]="content" mat-dialog-content></div>
    </div>
  </div>
</div>
