<div class="numeric-range">
    <mat-form-field class="numeric-range__item" appearance="outline">
        <mat-label>Min</mat-label>
        <mat-select [(value)]="min" (valueChange)="onMinChange()" id="numeric-range__{{label|slug}}-min">
            <mat-option id="numeric-range__{{label|slug}}-min-any"  [value]="0">Any</mat-option>
            <mat-option *ngFor="let value of getMinRange()" id="numeric-range__{{label|slug}}-min-{{value}}" [value]="value">{{ getMinDisplayValue(value) }}</mat-option>
        </mat-select>

    </mat-form-field>
    <mat-form-field class="numeric-range__item" appearance="outline">
        <mat-label>Max</mat-label>
        <mat-select [(value)]="max" (valueChange)="onMaxChange()" id="numeric-range__{{label|slug}}-max">
            <mat-option id="numeric-range__{{label|slug}}-max-any" [value]="0">Any</mat-option>
            <mat-option *ngFor="let value of getMaxRange()" id="numeric-range__{{label|slug}}-max-{{value}}" [value]="value">{{ getMaxDisplayValue(value) }}</mat-option>
        </mat-select>
    </mat-form-field>
</div>