import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { ZoomDir } from '../types/zoom-direction.type';

@Directive({
  selector: '[skychutePinchToZoom]',
})
export class PinchToZoomDirective {
  isScaling = false;
  initMoveDist = 0;

  @Output() zoom = new EventEmitter<'in' | 'out'>();

  @HostListener('touchstart', ['$event']) onTouchStart(e: TouchEvent) {
    // trigger only when it's a 2 point touch event
    if (e.touches.length === 2) {
      this.isScaling = true;
      this.pinchStart(e);
    }
  }

  @HostListener('touchmove', ['$event']) onTouchMove(e: TouchEvent): void {
    if (this.isScaling) {
      e.preventDefault();
      this.pinchMove(e);
    }
  }

  @HostListener('touchend') onTouchEnd(): void {
    if (this.isScaling) {
      this.isScaling = false;
    }
  }

  pinchStart(e: TouchEvent): void {
    this.initMoveDist = Math.hypot(
      e.touches[0].pageX - e.touches[1].pageX,
      e.touches[0].pageY - e.touches[1].pageY,
    );
  }

  pinchMove(e: TouchEvent): void {
    const dist = Math.hypot(
      e.touches[0].pageX - e.touches[1].pageX,
      e.touches[0].pageY - e.touches[1].pageY,
    );
    if (!this.initMoveDist) {
      this.initMoveDist = dist;
      return;
    }

    const zoomDir: ZoomDir = dist > this.initMoveDist ? 'in' : 'out';
    this.zoom.emit(zoomDir);
    this.initMoveDist = dist;
  }
}
