import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';

@Component({
  selector: 'skychute-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SlideToggleComponent {
  @Input() checked = false;
  @Input() disabled = false;
  @Input() id: string;
  @Input() onLabel = 'ON';
  @Input() offLabel = 'OFF';
  @Input() width = '72px';
  @Output() toggleChange = new EventEmitter<boolean>();
  toggle(event: MatSlideToggleChange): void {
    this.toggleChange.emit(event.checked);
  }
}
