<ul class="pagination" *ngIf="!autoHide || (autoHide && totalPages > 1)">
  <li class="pagination__li pagination__left-arrow" (click)="selectPage(currentPage - 1, $event)">
    <a class="pagination__li-btn pagination__a" href="" title="Go to previous page"
       [class.pagination__disabled]="currentPage === 1">
      <mat-icon>chevron_left</mat-icon>
    </a>
  </li>

  <li class="pagination__li" (click)="cancelEvent($event)" *ngIf="(currentPage - range) > 1">
    <a class="pagination__li-btn pagination__a" href="">...</a>
  </li>

  <li class="pagination__li" [ngClass]="{'pagination__li-current-a': (page === currentPage)}"
      *ngFor="let page of pages | async">
    <a class="pagination__li-btn pagination__a" href="javascript:void(0)" (click)="selectPage(page, $event)">
      {{page}}
    </a>
  </li>

  <li class="pagination__li" (click)="cancelEvent($event)" *ngIf="(currentPage + range) < totalPages">
    <a class="pagination__li-btn pagination__a" href="">...</a>
  </li>

  <li class="pagination__li pagination__right-arrow" (click)="selectPage(currentPage + 1, $event)">
    <a class="pagination__li-btn pagination__a" href="" title="Go to next page"
       [class.pagination__disabled]="currentPage === totalPages">
      <mat-icon>chevron_right</mat-icon>
    </a>
  </li>
</ul>
