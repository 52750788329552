import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoLoaderComponent } from './logo-loader/logo-loader.component';
import { BarLoaderComponent } from './bar-loader/bar-loader.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  imports: [CommonModule, MatProgressBarModule],
  declarations: [LogoLoaderComponent, BarLoaderComponent],
  exports: [LogoLoaderComponent, BarLoaderComponent],
})
export class UiLoaderModule {}
