import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distance',
})
export class DistancePipe implements PipeTransform {
  transform(value: any): any {
    if (typeof value === 'number' && !Number.isNaN(value)) {
      const decimalPipe = new DecimalPipe('en-AU');
      const decimalNumber = decimalPipe.transform(value, '1.1');

      return `${decimalNumber} m`;
    } else {
      return '—';
    }
  }
}
