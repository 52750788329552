<div class="team-switcher-dialog">
  <!-- Title -->
  <h1 mat-dialog-title>
    Switching to <strong>{{dialogData.role}}</strong> of <strong>{{dialogData.orgName}}</strong>
  </h1>
  <!-- Content -->
  <div mat-dialog-content>
    <form>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput type="email" [value]="email" readonly disabled
               autocomplete="username">
      </mat-form-field>
    </form>
    <!-- Loading Spinner -->
    <div class="team-switcher-dialog__spinner-container" *ngIf="isLoading">
      <mat-spinner mode="indeterminate" color="primary" class="team-switcher-dialog__spinner"></mat-spinner>
    </div>
    <!-- Error message -->
    <skychute-alert type="error" [message]="errorMsg" *ngIf="errorMsg"></skychute-alert>
  </div>
  <!-- Actions -->
  <div mat-dialog-actions class="team-switcher-dialog__actions">
    <button mat-flat-button (click)="closeDialog()">Cancel</button>
    <button mat-flat-button color="primary" (click)="switchTeam(dialogData.orgId)">Switch team</button>
  </div>
</div>
