import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform(value: Array<unknown>, searchTerm: string, searchableFields: string[] = []): unknown {
    if (!searchTerm) {
      return value;
    }
    const result = [];
    value.forEach((currentItem: unknown) => {
      let fakeItem = currentItem;
      if (searchableFields.length > 0) {
        fakeItem = Object.keys(currentItem)
          .filter((key) => searchableFields.includes(key))
          .reduce((obj, key) => {
            obj[key] = currentItem[key];
            return obj;
          }, {});
      }
      if (JSON.stringify(fakeItem).toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
        result.push(currentItem);
      } else if (
        JSON.stringify(fakeItem)
          .toLowerCase()
          .indexOf(searchTerm.toLowerCase().replace(/[^a-zA-Z ]/g, ' ')) > -1
      ) {
        result.push(currentItem);
      }
    });
    return result;
  }
}
