export function getWebAppUrl(branch: string): string {
  const featureBranchRegex = /feature-/i;
  const demoBranchRegex = /demo/i;
  const prodBranchRegex = /production/i;
  const isFeatureBranch = featureBranchRegex.test(branch);
  const isDemo = demoBranchRegex.test(branch);
  const isProd = prodBranchRegex.test(branch);
  if (document.location.href.match(/localhost/)) {
    return 'http://localhost:4200';
  }
  if (isFeatureBranch) {
    return `https://${branch.toLowerCase()}-webapp.projectredev.dev.skychute.com.au`;
  }
  if (isDemo) {
    return 'https://demoplatform.projectre.com.au';
  }
  if (isProd) {
    return 'https://platform.projectre.com.au';
  }
}
