import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';
import {
  DeleteInvitationMutation,
  DeleteInvitationMutationVariables,
  DropMembershipByUserIdMutation,
  DropMembershipByUserIdMutationVariables,
  GetContactInfoByIdQuery,
  GetContactInfoByIdQueryVariables,
  GetCurrentUsersByOrganisationIdQuery,
  GetCurrentUsersByOrganisationIdQueryVariables,
  GetInvitedUsersByOrganisationIdQuery,
  GetInvitedUsersByOrganisationIdQueryVariables,
  GetOrganisationDetailQuery,
  GetOrganisationDetailQueryVariables,
  InsertContactMutation,
  InsertContactMutationVariables,
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
  UpdatedOrganizationNameMutation,
  UpdatedOrganizationNameMutationVariables,
  UpdateInvitationByIdMutation,
  UpdateInvitationByIdMutationVariables,
  UpdateMemberByIdMutation,
  UpdateMemberByIdMutationVariables,
  WithdrawInvitationByIdMutation,
  WithdrawInvitationByIdMutationVariables,
} from '../../generated/lib/operations';
import { Contact_Insert_Input, Member_Role_Enum, Organisation_Set_Input } from '@skychute/schema';

const GET_ORGANISATION_DETAIL_FOR_INVITATION = gql`
  query getOrganisationDetail($userId: uuid, $organizationId: uuid) {
    organisation(
      where: { id: { _eq: $organizationId }, members: { user_id: { _eq: $userId } } }
      limit: 20
    ) {
      id
      name
      abn
      acn
      registered_for_gst
      address_line_1
      address_city_suburb
      address_state
      address_postcode
      address_country
      main_contact_id
      licensee_contact_id
      licence_number
      licence_state_of_issue
      main_contact {
        first_name
        last_name
        phone_number
        email
      }
      licensee_contact {
        first_name
        last_name
        phone_number
        email
      }
      members(where: { user_id: { _eq: $userId } }) {
        role
      }
    }
  }
`;

const GET_CURRENT_USERS_BY_ORGANISATION_ID = gql`
  query getCurrentUsersByOrganisationId(
    $conditions: [user_bool_exp!]
    $orderBy: [user_order_by!]
    $organisationId: uuid!
  ) {
    user(where: { _and: $conditions }, order_by: $orderBy) {
      id
      email
      first_name
      last_name
      is_enable
      phone_number
      members(where: { organisation_id: { _eq: $organisationId } }) {
        role
      }
      contacts(where: { organisation_id: { _eq: $organisationId } }) {
        id
      }
    }
  }
`;

const GET_INVITED_USERS_BY_ORGANISATION_ID = gql`
  query getInvitedUsersByOrganisationId(
    $conditions: [invitation_bool_exp!]
    $orderBy: [invitation_order_by!]
  ) {
    invitation(where: { _and: $conditions }, order_by: $orderBy) {
      id
      email
      status
      role
      created_at
      inviter {
        first_name
        last_name
      }
    }
  }
`;

const WITHDRAW_INVITATION_BY_ID = gql`
  mutation withdrawInvitationById($invitationId: uuid!) {
    update_invitation(where: { id: { _eq: $invitationId } }, _set: { status: WITHDRAWN }) {
      affected_rows
    }
  }
`;

const UPDATE_INVITATION_BY_ID = gql`
  mutation updateInvitationById($invitationId: uuid!, $newRole: member_role_enum) {
    update_invitation(where: { id: { _eq: $invitationId } }, _set: { role: $newRole }) {
      affected_rows
    }
  }
`;

const UPDATE_MEMBER_BY_ID = gql`
  mutation updateMemberById($userId: uuid!, $newRole: member_role_enum) {
    update_member(where: { user_id: { _eq: $userId } }, _set: { role: $newRole }) {
      affected_rows
    }
  }
`;

const DELETE_INVITED_USER = gql`
  mutation deleteInvitation($id: uuid!) {
    delete_invitation_by_pk(id: $id) {
      id
    }
  }
`;
const UPDATE__ORGANIZATION_NAME = gql`
  mutation updatedOrganizationName($teamId: uuid!, $name: String!) {
    update_organisation_by_pk(pk_columns: { id: $teamId }, _set: { name: $name }) {
      id
    }
  }
`;

const UPDATE_COMPANY = gql`
  mutation updateCompany($teamId: uuid!, $input: organisation_set_input) {
    update_organisation_by_pk(pk_columns: { id: $teamId }, _set: $input) {
      name
      abn
      acn
      address_line_1
      address_city_suburb
      address_postcode
      address_state
      address_country
    }
  }
`;

const GET_CONTACT_INFO_BY_ID = gql`
  query getContactInfoById($contactId: uuid!) {
    contact_by_pk(id: $contactId) {
      id
      first_name
      last_name
      email
      phone_number
    }
  }
`;

const REMOVE_MEMBER_FROM_ORG_BY_USER_ID = gql`
  mutation dropMembershipByUserId($userId: uuid!, $orgId: uuid!) {
    delete_member(where: { user_id: { _eq: $userId }, organisation_id: { _eq: $orgId } }) {
      affected_rows
    }
  }
`;

const CREATE_CONTACT = gql`
  mutation insertContact($input: contact_insert_input!) {
    insert_contact_one(object: $input) {
      id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class TeamSettingService {
  private teamName$ = new BehaviorSubject<string>('');
  private selectedRoleType = new BehaviorSubject<{ roleType: string }>({ roleType: '' });

  constructor(private apollo: Apollo) {}

  async getOrganisationsForInvitation(
    userId: string,
    organizationId: string,
  ): Promise<GetOrganisationDetailQuery['organisation']> {
    const resp = await firstValueFrom(
      this.apollo.query<GetOrganisationDetailQuery, GetOrganisationDetailQueryVariables>({
        query: GET_ORGANISATION_DETAIL_FOR_INVITATION,
        variables: {
          userId,
          organizationId,
        },
      }),
    );
    return resp.data.organisation;
  }

  async getCurrentUsers(
    variables: GetCurrentUsersByOrganisationIdQueryVariables,
  ): Promise<GetCurrentUsersByOrganisationIdQuery['user']> {
    const resp = await firstValueFrom(
      this.apollo.query<
        GetCurrentUsersByOrganisationIdQuery,
        GetCurrentUsersByOrganisationIdQueryVariables
      >({
        query: GET_CURRENT_USERS_BY_ORGANISATION_ID,
        variables: variables,
      }),
    );
    return resp.data.user;
  }

  async getInvitedUsers(
    $variables: GetInvitedUsersByOrganisationIdQueryVariables,
  ): Promise<GetInvitedUsersByOrganisationIdQuery['invitation']> {
    const resp = await firstValueFrom(
      this.apollo.query<
        GetInvitedUsersByOrganisationIdQuery,
        GetInvitedUsersByOrganisationIdQueryVariables
      >({
        query: GET_INVITED_USERS_BY_ORGANISATION_ID,
        variables: $variables,
      }),
    );
    return resp.data.invitation;
  }

  async withdrawInvitation(invitationId: string): Promise<number> {
    const resp = await firstValueFrom(
      this.apollo.mutate<WithdrawInvitationByIdMutation, WithdrawInvitationByIdMutationVariables>({
        mutation: WITHDRAW_INVITATION_BY_ID,
        variables: {
          invitationId,
        },
      }),
    );
    if (resp.errors) {
      throw new Error('Unable to withdraw invitation');
    }
    return resp.data.update_invitation.affected_rows;
  }

  async updateInvitation(invitationId: string, newRole: Member_Role_Enum): Promise<number> {
    const resp = await firstValueFrom(
      this.apollo.mutate<UpdateInvitationByIdMutation, UpdateInvitationByIdMutationVariables>({
        mutation: UPDATE_INVITATION_BY_ID,
        variables: {
          invitationId,
          newRole,
        },
      }),
    );
    if (resp.errors) {
      throw new Error('Unable to update invitation');
    }
    return resp.data.update_invitation.affected_rows;
  }

  async updateMember(userId: string, newRole: Member_Role_Enum): Promise<number> {
    const resp = await firstValueFrom(
      this.apollo.mutate<UpdateMemberByIdMutation, UpdateMemberByIdMutationVariables>({
        mutation: UPDATE_MEMBER_BY_ID,
        variables: {
          userId,
          newRole,
        },
      }),
    );
    if (resp.errors) {
      throw new Error('Unable to update member role');
    }
    return resp.data.update_member.affected_rows;
  }

  async deleteInvitation(id: string): Promise<DeleteInvitationMutation> {
    const resp = await firstValueFrom(
      this.apollo.mutate<DeleteInvitationMutation, DeleteInvitationMutationVariables>({
        mutation: DELETE_INVITED_USER,
        variables: {
          id,
        },
      }),
    );
    return resp.data;
  }

  setTeamName(teamName: string): void {
    this.teamName$.next(teamName);
  }

  getTeamName(): Observable<any> {
    return this.teamName$.asObservable();
  }

  setSelectedRoleType(roleType: string): void {
    this.selectedRoleType.next({ roleType: roleType });
  }

  async updateOrganizationName(
    teamId: string,
    name: string,
  ): Promise<UpdatedOrganizationNameMutation> {
    const resp = await firstValueFrom(
      this.apollo.mutate<UpdatedOrganizationNameMutation, UpdatedOrganizationNameMutationVariables>(
        {
          mutation: UPDATE__ORGANIZATION_NAME,
          variables: {
            teamId,
            name,
          },
        },
      ),
    );
    return resp.data;
  }

  async updateCompany(
    companyId: string,
    input: Organisation_Set_Input,
  ): Promise<UpdateCompanyMutation['update_organisation_by_pk']> {
    const resp = await firstValueFrom(
      this.apollo.mutate<UpdateCompanyMutation, UpdateCompanyMutationVariables>({
        mutation: UPDATE_COMPANY,
        variables: {
          teamId: companyId,
          input: input,
        },
      }),
    );
    return resp.data.update_organisation_by_pk;
  }

  getContactInfoById(contactId: string): Promise<ApolloQueryResult<GetContactInfoByIdQuery>> {
    return firstValueFrom(
      this.apollo.query<GetContactInfoByIdQuery, GetContactInfoByIdQueryVariables>({
        query: GET_CONTACT_INFO_BY_ID,
        variables: { contactId },
      }),
    );
  }

  async revokeAccessFromUser(userId: string, orgId: string): Promise<boolean> {
    const resp = await firstValueFrom(
      this.apollo.mutate<DropMembershipByUserIdMutation, DropMembershipByUserIdMutationVariables>({
        mutation: REMOVE_MEMBER_FROM_ORG_BY_USER_ID,
        variables: {
          userId,
          orgId,
        },
      }),
    );
    return !!resp.data?.delete_member?.affected_rows;
  }

  async createUserContact(input: Contact_Insert_Input): Promise<string> {
    const resp = await firstValueFrom(
      await this.apollo.mutate<InsertContactMutation, InsertContactMutationVariables>({
        mutation: CREATE_CONTACT,
        variables: {
          input,
        },
      }),
    );
    return resp.data?.insert_contact_one?.id;
  }
}
