import { Pipe, PipeTransform } from '@angular/core';
import { FloorPlanModel } from '@skychute/shared-models';

@Pipe({
  name: 'sortByAspect',
  pure: false,
})
export class SortByAspectPipe implements PipeTransform {
  transform(value: FloorPlanModel[]): FloorPlanModel[] {
    if (value !== undefined) {
      return value.sort((firstProp: FloorPlanModel, secondProp: FloorPlanModel) => {
        const firstControl = this.getValue(firstProp.aspect);
        const secondControl = this.getValue(secondProp.aspect);
        if (firstControl < secondControl) {
          return -1;
        } else if (firstControl > secondControl) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    return value;
  }

  getValue(aspect: string): string {
    let alias = 'a';
    switch (aspect) {
      case 'N':
        alias = 'a';
        break;
      case 'NE':
        alias = 'b';
        break;
      case 'E':
        alias = 'c';
        break;
      case 'SE':
        alias = 'd';
        break;
      case 'S':
        alias = 'e';
        break;
      case 'SW':
        alias = 'f';
        break;
      case 'W':
        alias = 'g';
        break;
      default:
        alias = 'h';
        break;
    }

    return alias;
  }
}
