import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadedDocumentComponent } from './uploaded-document/uploaded-document.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule],
  declarations: [UploadedDocumentComponent],
  exports: [UploadedDocumentComponent],
})
export class UiUploadedDocumentModule {}
