<div class="logo">
  <img *ngIf="logoURLx1"
       srcset="{{logoURLx1|cdn}}, {{logoURLx2|cdn}} 2x"
       [alt]="text"
       class="logo__img"
       id="logo__img" />
  <span *ngIf="!logoURLx1" class="logo__text-container" [style.background]="bgColor">
    <span class="logo__text" id="logo__text" [ngClass]="{'logo__text--small': size === 'small'}"
          [style.color]="fgColor">{{ shortName(text) }}</span>
  </span>
</div>
