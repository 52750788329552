import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from './base-component/base.component';
import { LowDashPipe } from './pipes/lowdash.pipe';
import { ShortPricePipe } from './pipes/short-price.pipe';
import { ContrastColourPipe } from './pipes/contrast-colour.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { PhoneNumberMaskPipe } from './pipes/phoneNumber-mask.pipe';
import { CurrencyMaskPipe } from './pipes/currency-mask.pipe';
import { SlugPipe } from './pipes/slug.pipe';
import { EnumPipe } from './pipes/enum.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { UserFromEmailPipe } from './pipes/user-from-email.pipe';
import { DomainFromEmailPipe } from './pipes/domain-from-email.pipe';
import { PinchToZoomDirective } from './directives/pinch-to-zoom.directive';
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { MomentFormatPipe } from './pipes/moment-format.pipe';
import {
  PartnerPortalExistingLoginValidatorDirective,
} from './validators/partner-portal-existing-login.validator';

@NgModule({
  imports: [CommonModule],
  declarations: [
    BaseComponent,
    LowDashPipe,
    DateFormatPipe,
    ShortPricePipe,
    SearchPipe,
    ContrastColourPipe,
    PhoneNumberMaskPipe,
    CurrencyMaskPipe,
    SlugPipe,
    SortPipe,
    EnumPipe,
    TimeAgoPipe,
    UserFromEmailPipe,
    DomainFromEmailPipe,
    PinchToZoomDirective,
    OrdinalPipe,
    SafeHtmlPipe,
    MomentFormatPipe,
    PartnerPortalExistingLoginValidatorDirective,
  ],
  exports: [
    BaseComponent,
    LowDashPipe,
    DateFormatPipe,
    ShortPricePipe,
    ContrastColourPipe,
    PhoneNumberMaskPipe,
    CurrencyMaskPipe,
    SearchPipe,
    SortPipe,
    SlugPipe,
    EnumPipe,
    TimeAgoPipe,
    UserFromEmailPipe,
    DomainFromEmailPipe,
    PinchToZoomDirective,
    OrdinalPipe,
    SafeHtmlPipe,
    MomentFormatPipe,
    PartnerPortalExistingLoginValidatorDirective,
  ],
})
export class SharedModelsModule {
}
