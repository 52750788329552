/**
 * This class contains some constants we use for seed script
 * Named constants are easier to read than long numeric ids
 */
export class SeedId {
  // Hubspot Id
  static readonly HUBSPOT_SANDBOX_INSTANCE_ID = "44862234";

  // you can use "skychutedev+{name}@gmail.com" variations
  static readonly EMAIL = 'skychutedev@gmail.com';
  // Jest tests

  // Agent Org
  static readonly JEST_AGENT_MEMBER_EMAIL = 'skychutedev+agent-member@gmail.com';
  static readonly JEST_AGENT_ADMIN_EMAIL = 'skychutedev+agent-admin@gmail.com';
  // Developer Org
  static readonly JEST_DEVELOPER_MEMBER_EMAIL = 'skychutedev+developer-member@gmail.com';
  static readonly JEST_DEVELOPER_ADMIN_EMAIL = 'skychutedev+developer-admin@gmail.com';
  // Agent Org 2
  static readonly JEST_AGENT2_MEMBER_EMAIL = 'skychutedev+agent2-member@gmail.com';
  static readonly JEST_AGENT2_ADMIN_EMAIL = 'skychutedev+agent2-admin@gmail.com';
  // Developer Org 2
  static readonly JEST_DEVELOPER2_MEMBER_EMAIL = 'skychutedev+developer2-member@gmail.com';
  static readonly JEST_DEVELOPER2_ADMIN_EMAIL = 'skychutedev+developer2-admin@gmail.com';

  static readonly JEST_DEVELOPER_A_EMAIL = 'skychutedev+dev-a@gmail.com';
  static readonly JEST_DEVELOPER_B_EMAIL = 'skychutedev+dev-b@gmail.com';
  static readonly JEST_AGENT_A_EMAIL = 'skychutedev+agent-a@gmail.com';
  static readonly JEST_AGENT_B_EMAIL = 'skychutedev+agent-b@gmail.com';
  static readonly JEST_SOLICITOR_A_EMAIL = 'skychutedev+sol-a@gmail.com';
  static readonly JEST_SOLICITOR_B_EMAIL = 'skychutedev+sol-b@gmail.com';
  static readonly JEST_SOLICITOR_ABP_EMAIL = 'skychutedev+sol-abp@gmail.com';
  static readonly JEST_LEAD_A_EMAIL = 'skychutedev+lead-a@gmail.com';
  static readonly JEST_LEAD_B_EMAIL = 'skychutedev+lead-b@gmail.com';
  static readonly JEST_LEAD_C_EMAIL = 'skychutedev+lead-c@gmail.com';
  static readonly JEST_LEAD_D_EMAIL = 'skychutedev+lead-c@gmail.com';
  static readonly JEST_EMAILS = [
    SeedId.JEST_DEVELOPER_A_EMAIL,
    SeedId.JEST_DEVELOPER_B_EMAIL,
    SeedId.JEST_AGENT_A_EMAIL,
    SeedId.JEST_AGENT_B_EMAIL,
    SeedId.JEST_SOLICITOR_A_EMAIL,
    SeedId.JEST_SOLICITOR_B_EMAIL,
    SeedId.JEST_LEAD_A_EMAIL,
    SeedId.JEST_LEAD_B_EMAIL,
    SeedId.JEST_LEAD_C_EMAIL,
    SeedId.JEST_LEAD_D_EMAIL,
    SeedId.JEST_AGENT_MEMBER_EMAIL,
    SeedId.JEST_AGENT_ADMIN_EMAIL,
    SeedId.JEST_DEVELOPER_MEMBER_EMAIL,
    SeedId.JEST_DEVELOPER_ADMIN_EMAIL,
    SeedId.JEST_AGENT2_MEMBER_EMAIL,
    SeedId.JEST_AGENT2_ADMIN_EMAIL,
    SeedId.JEST_DEVELOPER2_MEMBER_EMAIL,
    SeedId.JEST_DEVELOPER2_ADMIN_EMAIL,
    SeedId.JEST_SOLICITOR_ABP_EMAIL,
  ];
  static readonly JEST_LOGIN_EMAIL = [
    SeedId.JEST_LEAD_A_EMAIL,
    SeedId.JEST_LEAD_B_EMAIL,
    SeedId.JEST_AGENT_MEMBER_EMAIL,
    SeedId.JEST_AGENT_ADMIN_EMAIL,
    SeedId.JEST_DEVELOPER_MEMBER_EMAIL,
    SeedId.JEST_DEVELOPER_ADMIN_EMAIL,
    SeedId.JEST_AGENT2_MEMBER_EMAIL,
    SeedId.JEST_AGENT2_ADMIN_EMAIL,
    SeedId.JEST_DEVELOPER2_MEMBER_EMAIL,
    SeedId.JEST_DEVELOPER2_ADMIN_EMAIL,
    SeedId.JEST_SOLICITOR_ABP_EMAIL,
  ];
  static readonly JEST_DEAL_NAME = 'Jest Test';
  //
  static readonly CYPRESS_USER_EMAIL = 'skychutedev+cypress@gmail.com';

  //
  // Organisations
  // when adding new orgs, make sure to add them into CypressService.deleteAllFeaturesOnSeedOrgs() method
  //
  static readonly DEVELOPER_ORG_ID = '00000000-0000-0000-0000-000000000001';
  static readonly AGENT_ORG_ID = '00000000-0000-0000-0000-000000000002';
  static readonly SOLICITOR_ABP_LAWYERS_ID = '00000000-0000-0000-0000-000000000003';
  static readonly SOLICITOR_ABS_CONVEYANCING_ID = '00000000-0000-0000-0000-000000000004';
  static readonly SOLICITOR_TBA_ID = '00000000-0000-0000-0000-000000000005';
  static readonly EMPTY_AGENT_TEST_ORG_ID = '00000000-0000-0000-0000-000000000006';
  // organisation (team) for users who were invited into BuyerPortal, !!!DON'T USE THIS ID!!! for other seed organisations
  // this particular value is reserved, you should not insert org with given ID into db
  static readonly OTHER_BUYER_PORTAL = '00000000-0000-0000-0000-000000000007';
  // organisation (team) for users who just signed up, !!!DON'T USE THIS ID!!! for other seed organisations
  // this particular value is reserved, you should not insert org with given ID into db
  static readonly OTHER_EMPTY_ORG = '00000000-0000-0000-0000-000000000008';
  static readonly AGENT_ORG_2_ID = '00000000-0000-0000-0000-000000000009';
  static readonly DEVELOPER_ORG_2_ID = '00000000-0000-0000-0000-000000000010';
  // for Salesforce tests only
  static readonly SALESFORCE_AGENT_ORG_1_ID = '00000000-0000-0000-0000-000000000011';

  //
  // Projects
  //
  static readonly PROJECT_MARS_TEST_PROJECT_ID = '10000000-0000-0000-0000-000000000001';
  static readonly PROJECT_THE_MERCURY_ID = '10000000-0000-0000-0000-000000000002';
  static readonly PROJECT_RIDGELINE_ID = '10000000-0000-0000-0000-000000000003';
  static readonly PROJECT_POLYGONIA_1_ID = '10000000-0000-0000-0000-000000000004';
  static readonly PROJECT_POLYGONIA_2_ID = '10000000-0000-0000-0000-000000000005';
  static readonly PROJECT_POLYGONIA_3_ID = '10000000-0000-0000-0000-000000000006';
  static readonly PROJECT_POLYGONIA_4_ID = '10000000-0000-0000-0000-000000000007';
  static readonly PROJECT_VENUS_TEST_PROJECT_ID = '10000000-0000-0000-0000-000000000008';
  static readonly PROJECT_SATURN_ID = '10000000-0000-0000-0000-000000000009'; // apartment
  static readonly PROJECT_EARTH_ID = '10000000-0000-0000-0000-000000000010'; // land

  static readonly SEED_PROJECT_IDS = [
    this.PROJECT_MARS_TEST_PROJECT_ID,
    this.PROJECT_THE_MERCURY_ID,
    this.PROJECT_RIDGELINE_ID,
    this.PROJECT_POLYGONIA_1_ID,
    this.PROJECT_POLYGONIA_2_ID,
    this.PROJECT_POLYGONIA_3_ID,
    this.PROJECT_POLYGONIA_4_ID,
    this.PROJECT_VENUS_TEST_PROJECT_ID,
    this.PROJECT_SATURN_ID,
    this.PROJECT_EARTH_ID,
  ];

  //
  // Stages / Buildings
  //
  static readonly BUILDING_MARS_ID = '20000000-0000-0000-0000-000000000001';

  static readonly STAGE_RIDGELINE_RELEASE_1_ID = '20000000-0000-0000-0000-000000000002';
  static readonly STAGE_RIDGELINE_RELEASE_2_ID = '20000000-0000-0000-0000-000000000003';
  static readonly STAGE_RIDGELINE_RELEASE_3_ID = '20000000-0000-0000-0000-000000000004';
  static readonly STAGE_RIDGELINE_RELEASE_4_ID = '20000000-0000-0000-0000-000000000005';
  static readonly STAGE_RIDGELINE_RELEASE_5_ID = '20000000-0000-0000-0000-000000000006';
  static readonly STAGE_RIDGELINE_RELEASE_6_ID = '20000000-0000-0000-0000-000000000007';
  static readonly STAGE_RIDGELINE_RELEASE_7_ID = '20000000-0000-0000-0000-000000000008';
  static readonly STAGE_RIDGELINE_RELEASE_8_ID = '20000000-0000-0000-0000-000000000009';
  static readonly STAGE_RIDGELINE_RELEASE_9_ID = '20000000-0000-0000-0000-000000000010';
  static readonly STAGE_RIDGELINE_RELEASE_10_ID = '20000000-0000-0000-0000-000000000011';

  static readonly STAGE_POLYGONIA_1_1_ID = '20000000-0000-0000-0000-000000000012';
  static readonly STAGE_POLYGONIA_1_2_ID = '20000000-0000-0000-0000-000000000013';
  static readonly STAGE_POLYGONIA_1_3_ID = '20000000-0000-0000-0000-000000000014';

  static readonly STAGE_POLYGONIA_2_1_ID = '20000000-0000-0000-0000-000000000015';
  static readonly STAGE_POLYGONIA_2_2_ID = '20000000-0000-0000-0000-000000000016';
  static readonly STAGE_POLYGONIA_2_3_ID = '20000000-0000-0000-0000-000000000017';

  static readonly STAGE_POLYGONIA_3_1_ID = '20000000-0000-0000-0000-000000000018';
  static readonly STAGE_POLYGONIA_3_2_ID = '20000000-0000-0000-0000-000000000019';
  static readonly STAGE_POLYGONIA_3_3_ID = '20000000-0000-0000-0000-000000000020';

  static readonly STAGE_POLYGONIA_4_1_ID = '20000000-0000-0000-0000-000000000021';
  static readonly STAGE_POLYGONIA_4_2_ID = '20000000-0000-0000-0000-000000000022';

  static readonly BUILDING_THE_MERCURY_ID = '20000000-0000-0000-0000-000000000023';

  static readonly STAGE_VENUS_TEST_PROJECT_1_ID = '20000000-0000-0000-0000-000000000024';

  static readonly BUILDING_SATURN_ID = '20000000-0000-0000-0000-000000000025';

  static readonly STAGE_EARTH_1_ID = '20000000-0000-0000-0000-000000000026';
  static readonly STAGE_EARTH_2_ID = '20000000-0000-0000-0000-000000000027';

  //
  // SubStages
  //
  static readonly SUB_STAGE_RIDGELINE_RELEASE_7_1 = '30000000-0000-0000-0000-000000000001';
  static readonly SUB_STAGE_RIDGELINE_RELEASE_7_2 = '30000000-0000-0000-0000-000000000002';

  static readonly SUB_STAGE_RIDGELINE_RELEASE_8_1 = '30000000-0000-0000-0000-000000000003';
  static readonly SUB_STAGE_RIDGELINE_RELEASE_8_2 = '30000000-0000-0000-0000-000000000004';
  static readonly SUB_STAGE_RIDGELINE_RELEASE_8_3 = '30000000-0000-0000-0000-000000000005';

  static readonly SUB_STAGE_RIDGELINE_RELEASE_9_1 = '30000000-0000-0000-0000-000000000006';
  static readonly SUB_STAGE_RIDGELINE_RELEASE_9_2 = '30000000-0000-0000-0000-000000000007';
  static readonly SUB_STAGE_RIDGELINE_RELEASE_9_3 = '30000000-0000-0000-0000-000000000008';
  static readonly SUB_STAGE_RIDGELINE_RELEASE_9_4 = '30000000-0000-0000-0000-000000000009';
  static readonly SUB_STAGE_RIDGELINE_RELEASE_9_5 = '30000000-0000-0000-0000-000000000010';
  static readonly SUB_STAGE_RIDGELINE_RELEASE_9_6 = '30000000-0000-0000-0000-000000000011';
  static readonly SUB_STAGE_RIDGELINE_RELEASE_9_7 = '30000000-0000-0000-0000-000000000012';
  static readonly SUB_STAGE_RIDGELINE_RELEASE_9_8 = '30000000-0000-0000-0000-000000000013';
  static readonly SUB_STAGE_RIDGELINE_RELEASE_9_9 = '30000000-0000-0000-0000-000000000014';

  static readonly SUB_STAGE_RIDGELINE_RELEASE_10_1 = '30000000-0000-0000-0000-000000000015';
  static readonly SUB_STAGE_RIDGELINE_RELEASE_10_2 = '30000000-0000-0000-0000-000000000016';
  static readonly SUB_STAGE_RIDGELINE_RELEASE_10_3 = '30000000-0000-0000-0000-000000000017';

  static readonly SUB_STAGE_POLYGONIA_3_3_1 = '30000000-0000-0000-0000-000000000018';
  static readonly SUB_STAGE_POLYGONIA_3_3_2 = '30000000-0000-0000-0000-000000000019';

  static readonly SUB_STAGE_POLYGONIA_4_1_1 = '30000000-0000-0000-0000-000000000020';
  static readonly SUB_STAGE_POLYGONIA_4_1_2 = '30000000-0000-0000-0000-000000000021';

  static readonly SUB_STAGE_POLYGONIA_4_2_1 = '30000000-0000-0000-0000-000000000022';
  static readonly SUB_STAGE_POLYGONIA_4_2_2 = '30000000-0000-0000-0000-000000000023';

  static readonly SUB_STAGE_EARTH_2_1 = '30000000-0000-0000-0000-000000000024';
  static readonly SUB_STAGE_EARTH_2_1_1 = '30000000-0000-0000-0000-000000000025';

  //
  // Attachments
  //
  static readonly ATT_MARS_LOGO_ID = '40000000-0000-0000-0000-000000000001';
  static readonly ATT_MARS_BG_ID = '40000000-0000-0000-0000-000000000002';

  static readonly ATT_THE_MERCURY_LOGO_ID = '40000000-0000-0000-0000-000000000003';
  static readonly ATT_THE_MERCURY_BG_ID = '40000000-0000-0000-0000-000000000004';

  //
  // Users
  //
  static readonly USER_SOLICITOR_ABP_1_ID = '50000000-0000-0000-0000-000000000001';
  static readonly CONTACT_SOLICITOR_ABP_1_ID = '50000000-0000-0000-0000-000000000001';
  static readonly USER_SOLICITOR_ABP_2_ID = '50000000-0000-0000-0000-000000000002';
  static readonly USER_SOLICITOR_ABS_1_ID = '50000000-0000-0000-0000-000000000003';
  static readonly USER_SOLICITOR_ABS_2_ID = '50000000-0000-0000-0000-000000000004';
  static readonly USER_AGENT_SEED_OWNER_ID = '50000000-0000-0000-0000-000000000005';
  static readonly USER_DEVELOPER_SEED_OWNER_ID = '50000000-0000-0000-0000-000000000006';

  //
  // Properties
  //

  // Project Earth, Developer Org 2
  static readonly PROPERTY_ERT_1001_ID = '002c5cca-ee10-4284-a60f-a1e142992641';
  // Earth -> Stage Earth 2 -> ERT 2001
  static readonly PROPERTY_ERT_2001_ID = '60000000-0000-0000-0000-000000002001';
  static readonly PROPERTY_ERT_2002_ID = '60000000-0000-0000-0000-000000002002';
  // Earth -> Stage Earth 2 -> Sub Stage Earth 2.1 -> ERT 2101
  static readonly PROPERTY_ERT_2101_ID = '002c5cca-ee10-4284-a60f-a1e142992642';
  // Earth -> Stage Earth 2 -> Sub Stage Earth 2.1 -> Sub Stage Earth 2.1.1 -> ERT 2111
  static readonly PROPERTY_ERT_2111_ID = '60000000-0000-0000-0000-000000002111';
  // Earth -> Stage Earth 2 -> Sub Stage Earth 2.1 -> Sub Stage Earth 2.1.1 -> ERT 2112
  static readonly PROPERTY_ERT_2112_ID = '60000000-0000-0000-0000-000000002112';

  //
  // Listings
  //
  static readonly LISTING_ERT_1001_ID = '211db98f-e694-4013-a4a1-21aa53e37600';
  static readonly LISTING_ERT_2101_ID = '211db98f-e694-4013-a4a1-21aa53e37601';
  static readonly LISTING_ERT_2001_ID = '70000000-0000-0000-0000-000000002001';
  static readonly LISTING_ERT_2002_ID = '70000000-0000-0000-0000-000000002002';
  static readonly LISTING_ERT_2111_ID = '70000000-0000-0000-0000-000000002003';
  static readonly LISTING_ERT_2112_ID = '70000000-0000-0000-0000-000000002004';

  static readonly LISTING_RIDGELINE_9_9_2282 = '40000000-d404-0000-0000-0000022B2282';

  //
  // CRM Connections (Salesforce)
  //
  static readonly CRM_LOCAL_1_ID = '80000000-0000-0000-0000-000000000001';
  static readonly SF_PROJECT_RIDGELINE_ID = '80000000-0000-0000-0000-000000000002';
  static readonly SF_STAGE_RIDGELINE_RELEASE_9_ID = '80000000-0000-0000-0000-000000000003';
  static readonly SF_SUB_STAGE_RIDGELINE_RELEASE_9_9_ID = '80000000-0000-0000-0000-000000000004';
  static readonly SF_LISTING_RIDGELINE_2290_ID = '80000000-0000-0000-0000-000000000005';
  static readonly SF_SALESFORCE_AGENT_ORG_1_ID = '80000000-0000-0000-0000-000000000005';

  //
  // Properties
  //
  static readonly PROPERTY_RIDGELINE_9_9_2282 = '30000000-d404-0000-0000-0000022B2282';

  //
  // Deals
  //
  static readonly DEAL_1 = '00049200-0000-9980-0000-000000000000';
  static readonly DEAL_2 = '00049200-0000-9981-0000-000000000000';
  static readonly DEAL_3 = '00049200-0000-9982-0000-000000000000';
  static readonly DEAL_4 = '00049200-0000-9983-0000-000000000000';
  static readonly DEAL_5 = '00049200-0000-9984-0000-000000000000';
  static readonly DEAL_6 = '00049200-0000-9985-0000-000000000000';

  //
  // Listings for cypress
  //
  static readonly LISTING_RIDGELINE_1128 = '40000000-d404-0000-0000-000000111128';
  static readonly LISTING_RIDGELINE_1184 = '40000000-d404-0000-0000-000000111184';
  static readonly LISTING_RIDGELINE_1240 = '40000000-d404-0000-0000-000000111240';
  static readonly LISTING_RIDGELINE_1186 = '40000000-d404-0000-0000-000000111186';
  static readonly LISTING_RIDGELINE_1187 = '40000000-d404-0000-0000-000000111187';
  static readonly LISTING_RIDGELINE_1235 = '40000000-d404-0000-0000-000000111235';
  static readonly LISTING_RIDGELINE_1238 = '40000000-d404-0000-0000-000000111238';
  static readonly LISTING_RIDGELINE_1236 = '40000000-d404-0000-0000-000000111236';
  static readonly LISTING_RIDGELINE_1239 = '40000000-d404-0000-0000-000000111239';

  static readonly SRC_PHONE_NO = '+61488880683';
  static readonly DST_PHONE_NO = '+61483907765';

  //
  // Feature Packs
  //
  static readonly FEATURE_PACK_ID_STOCK_MANAGEMENT = '6bed1bf8-424d-440d-8b8b-588f225c79cb';
  static readonly FEATURE_PACK_ID_MARKETING_MANAGEMENT = 'fac83ad0-0ecf-46a9-8d03-56bea0657389';

  // when we want to tell some process that something is waiting for data from
  // Salesforce
  static readonly WAIT_FOR_SF = 'WAIT FOR SF';
  static readonly PROJECTRE_SYSTEM_ID ='00000000-0001-0001-0001-000000000001';
}
