import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency } from '@angular/common';

@Pipe({
  name: 'currencyMask',
})
export class CurrencyMaskPipe implements PipeTransform {
  transform(val: any): string {
    // Format the output to display any way you want here.
    // For instance:
    if (val === undefined) {
      return '';
    }
    let price: number;
    if (typeof val === 'number') {
      price = val;
    } else {
      price = parseInt(val, 10);
    }
    return formatCurrency(price, 'en-US', '$', 'AUD', '1.0-0');
  }
}
