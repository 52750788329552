import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { Observable, switchMap, timer } from 'rxjs';
import { InvitationService } from '@skychute/shared-services';

// Usage for Email validation
/*
<mat-form-field appearance="outline">
  <mat-label>Licence holder Email</mat-label>
<input matInput formControlName="licence_holder_email"
  [partnerPortalExistingLoginValidator]="invitationId">
<mat-error *ngIf="form.controls.licence_holder_email.hasError('isTakenErr')">
  Unable to verify if given Email was taken or not. Please contact support.
</mat-error>
<mat-error *ngIf="form.controls.licence_holder_email.hasError('isTaken')">
  Given Email already exists in the system and can't be used
</mat-error>
</mat-form-field>
*/

// Usage for Phone validation
/*
<mat-form-field appearance="outline">
<mat-label>Licence holder phone number</mat-label>
<input matInput formControlName="licence_holder_phone"
       [partnerPortalExistingLoginValidator]="invitationId">
<mat-error *ngIf="form.controls.licence_holder_phone.hasError('isTakenErr')">
  Unable to verify if given Phone was taken or not. Please contact support.
</mat-error>
<mat-error *ngIf="form.controls.licence_holder_phone.hasError('isTaken')">
  Given Phone already exists in the system and can't be used.
</mat-error>
</mat-form-field>
*/


@Directive({
  selector: '[partnerPortalExistingLoginValidator]',
  providers: [{
    provide: NG_ASYNC_VALIDATORS,
    useExisting: PartnerPortalExistingLoginValidatorDirective,
    multi: true,
  }],
})
export class PartnerPortalExistingLoginValidatorDirective implements AsyncValidator {
  @Input('partnerPortalExistingLoginValidator') partnerPortalExistingLoginValidator: string;

  constructor(private invitation: InvitationService) {
  }

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return timer(500).pipe(
      switchMap(async () => {
        if (!control.value) {
          // if control is empty return null
          return Promise.resolve(null);
        }
        const resp = await this.invitation.partnerPortalIsLoginTaken(control.value, this.partnerPortalExistingLoginValidator);
        if (!resp.success) {
          return { isTakenErr: true };
        }
        if (resp.isTaken) {
          return { isTaken: true };
        }
        return null;
      }),
    );
  }
}
