import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'domainFromEmail' })
export class DomainFromEmailPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }
    return `@${value.split('@')[1]}`;
  }
}
