import { Pipe, PipeTransform } from '@angular/core';
import { CardImageModel } from '@skychute/shared-models';
import { Attachment_Type_Enum } from '@skychute/schema';

@Pipe({
  name: 'sortByCustomOrder',
  pure: false,
})
export class SortAttachmentByCustomOrderPipe implements PipeTransform {
  transform(value: CardImageModel[]): CardImageModel[] {
    if (value !== undefined) {
      return value.sort((firstProp: CardImageModel, secondProp: CardImageModel) => {
        const firstControl = this.getValue(firstProp);
        const secondControl = this.getValue(secondProp);
        if (firstControl < secondControl) {
          return -1;
        } else if (firstControl > secondControl) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    return value;
  }

  getValue(plan: CardImageModel): string {
    if (plan.type === Attachment_Type_Enum.FloorPlan) {
      return 'a';
    } else if (plan.type === Attachment_Type_Enum.LinenPlan) {
      return 'b';
    } else if (plan.type === Attachment_Type_Enum.Photo && !plan.productTypes.includes('all')) {
      return 'c';
    } else if (plan.type === Attachment_Type_Enum.Photo && plan.productTypes.includes('all')) {
      return 'd';
    } else if (plan.type === Attachment_Type_Enum.Media) {
      return 'e';
    }
    return 'f'; // other else ordered last
  }
}
