<div class="stripe">
  <skychute-alert [message]="cardErrorMessage" type="error"></skychute-alert>
  <div class="pb-4">
    <div class="field">
      <div #cardNumberEle class="input empty"></div>
      <label>Card number</label>
      <div class="baseline"></div>
    </div>
  </div>

  <div class="mb-48 stripe-payment-method__expiry">

    <div class="field">
      <div>
        <div #cardExpiryEle class="input empty"></div>
        <label>Expiry date</label>
        <div class="baseline"></div>
      </div>
    </div>
    <div class="field">
      <div>
        <div #cardCvcEle class="input empty"></div>
        <label>Security code</label>
        <div class="baseline"></div>
      </div>
    </div>

  </div>

  <div class="error" id="card-errors" role="alert" *ngIf="errorMessage" [innerHTML]="errorMessage"></div>
<!--We accept-->
  <div class="stripe-payment-method__we-accept">
    <div class="stripe-payment-method__we-accept-text">We accept</div>
    <img width="50" height="32" [src]="'/assets/master-card.svg'" alt="master card"/>
    <img width="50" height="32" [src]="'/assets/visa-card.svg'" alt="visa card"/>
  </div>
<!--We accept-->
</div>
