import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import {
  CreateOrganisationMutation,
  CreateOrganisationMutationVariables,
  GetTeamByIdQuery,
  GetTeamByIdQueryVariables,
  GetTeamQuery,
  GetTeamQueryVariables,
  GetTeamToSetUpQuery,
  GetTeamToSetUpQueryVariables,
  GetVerifiedTeamUsersQuery,
  GetVerifiedTeamUsersQueryVariables,
  SetTeamIdMutation,
  SetTeamIdMutationVariables,
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
} from '../generated/lib/operations';
import { JwtService } from '@skychute/jwt';
import { firstValueFrom } from 'rxjs';
import { Organisation_Set_Input } from '@skychute/schema';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  constructor(private apollo: Apollo, private jwt: JwtService) {
  }

  /**
   * Returns organisation.id (team.id) of the team which can be set up by user
   * Returns empty string if not such team found
   */
  async getTeamToSetUp(): Promise<string> {
    const resp = await firstValueFrom(
      this.apollo.query<GetTeamToSetUpQuery, GetTeamToSetUpQueryVariables>({
        query: GET_TEAM_TO_SET_UP,
        variables: {
          userId: this.jwt.getUserId(),
        },
      }),
    );
    return resp.data?.member?.[0]?.organisation_id ?? '';
  }

  /**
   * Set current team_id for user with given id
   * Returns id of the user or empty string on error
   * @param userId
   * @param teamId
   */
  async setTeamId(userId: string, teamId: string): Promise<string> {
    const resp = await firstValueFrom(
      this.apollo.mutate<SetTeamIdMutation, SetTeamIdMutationVariables>({
        mutation: SET_TEAM_ID,
        variables: {
          userId,
          teamId,
        },
      }),
    );
    return resp.data?.update_user_by_pk?.id ?? '';
  }

  /**
   * Returns organisation (team) of the login user
   * Returns null if not such team found
   */
  async getCurrentTeam(): Promise<GetTeamQuery['organisation_by_pk']> {
    const resp = await firstValueFrom(
      this.apollo.query<GetTeamQuery, GetTeamQueryVariables>({
        query: GET_TEAM,
        variables: {
          teamId: this.jwt.getTeamId(),
        },
      }),
    );
    return resp.data.organisation_by_pk ?? null;
  }

  async getTeamById(id: string): Promise<GetTeamQuery['organisation_by_pk']> {
    const resp = await firstValueFrom(
      this.apollo.query<GetTeamByIdQuery, GetTeamByIdQueryVariables>({
        query: GET_TEAM_BY_ID,
        variables: {
          teamId: id,
        },
      }),
    );
    const org = resp.data.organisation_by_pk;
    if (org) {
      org.abn = org.abn ?? '';
      org.acn = org.acn ?? '';
      org.full_address = org.full_address ?? '';
    }
    return org ?? null;
  }

  async updateCompany(
    companyId: string,
    input: Organisation_Set_Input,
  ): Promise<UpdateCompanyMutation['update_organisation_by_pk']> {
    const resp = await firstValueFrom(
      this.apollo.mutate<UpdateCompanyMutation, UpdateCompanyMutationVariables>({
        mutation: UPDATE_COMPANY,
        variables: {
          teamId: companyId,
          input: input,
        },
      }),
    );
    if (resp.errors) {
      console.error(JSON.stringify(resp.errors, null, 4));
    }
    return resp.data.update_organisation_by_pk;
  }

  async createCompany(input: Organisation_Set_Input): Promise<string> {
    const resp = await firstValueFrom(
      this.apollo.mutate<CreateOrganisationMutation, CreateOrganisationMutationVariables>({
        mutation: CREATE_ORG,
        variables: {
          input,
        },
      }),
    );
    return resp.data.insert_organisation_one?.id;
  }

  async getVerifiedTeamUsers(teamId: string): Promise<GetVerifiedTeamUsersQuery['user']> {
    const resp = await firstValueFrom(
      this.apollo.query<GetVerifiedTeamUsersQuery, GetVerifiedTeamUsersQueryVariables>({
        query: GET_VERIFIED_TEAM_USERS,
        variables: {
          teamId,
        },
      }),
    );
    return resp.data.user ?? [];
  }
}

const GET_TEAM_TO_SET_UP = gql`
  query getTeamToSetUp($userId: uuid!) {
    member(
      where: {
        user_id: { _eq: $userId }
        role: { _eq: ADMIN }
        organisation: { is_user_team: { _eq: true }, is_setup: { _eq: false } }
      }
    ) {
      organisation_id
    }
  }
`;

const SET_TEAM_ID = gql`
  mutation setTeamId($userId: uuid!, $teamId: uuid!) {
    update_user_by_pk(pk_columns: { id: $userId }, _set: { team_id: $teamId }) {
      id
    }
  }
`;

const GET_TEAM = gql`
  query getTeam($teamId: uuid!) {
    organisation_by_pk(id: $teamId) {
      name
      id
      abn
      acn
      address_line_1
      address_city_suburb
      address_postcode
      address_state
      address_country
      full_address
    }
  }
`;

const GET_TEAM_BY_ID = gql`
  query getTeamById($teamId: uuid!) {
    organisation_by_pk(id: $teamId) {
      name
      id
      abn
      acn
      full_address
    }
  }
`;

const UPDATE_COMPANY = gql`
  mutation updateCompany($teamId: uuid!, $input: organisation_set_input) {
    update_organisation_by_pk(pk_columns: { id: $teamId }, _set: $input) {
      name
      id
      abn
      acn
      address_line_1
      address_city_suburb
      address_postcode
      address_state
      address_country
    }
  }
`;

const CREATE_ORG = gql`
  mutation createOrganisation($input: organisation_insert_input!) {
    insert_organisation_one(object: $input) {
      id
    }
  }
`;

const GET_VERIFIED_TEAM_USERS = gql`
  query getVerifiedTeamUsers($teamId: uuid!) {
    user(where: {
      email_verified: {_eq: true}
      members: {
        organisation_id: {_eq: $teamId}
      }
    }) {
      id
      first_name
      last_name
      email
      phone_number
    }
  }
`;
