import { Pipe, PipeTransform } from '@angular/core';
import { CdnService } from '../services/cdn.service';

@Pipe({
  name: 'cdn',
})
export class CdnPipe implements PipeTransform {
  constructor(private cdn: CdnService) {}

  transform(srcUrl: string): string {
    return this.cdn.getCdnUrl(srcUrl);
  }
}
