import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

/**
 * Usage: {{str | momentFormat:'YYYY-MM-DD'}}
 */
@Pipe({
  name: 'momentFormat',
})
export class MomentFormatPipe implements PipeTransform {
  transform(value: string | null | undefined, format: string | undefined = 'YYYY-MM-DD'): string {
    if (value === undefined || value === null || !value) {
      return '';
    }

    return moment(value).format(format);
  }
}
