import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Apollo, gql } from 'apollo-angular';
import {
  GetContactOfCurrentUserQuery,
  GetContactOfCurrentUserQueryVariables, GetMemberOfCurrentUserQuery, GetMemberOfCurrentUserQueryVariables,
  GetOrgByIdQuery,
  GetOrgByIdQueryVariables,
} from '../generated/lib/operations';
import { Member_Role_Enum, Organisation_Type_Enum } from '@skychute/schema';
import { JwtHasuraRole, JwtTokenModel } from '@skychute/shared-models';
import { firstValueFrom } from 'rxjs';

const GET_CONTACT_OF_CURRENT_USER = gql`
  query getContactOfCurrentUser($userId: uuid!) {
    user_by_pk(id: $userId) {
      contacts(limit: 1) {
        id
        first_name
        last_name
        email
        phone_number
        user {
          phone_number
        }
      }
    }
  }
`;

const GET_ORG_BY_ID = gql`
  query getOrgById($orgId: uuid!) {
    organisation_by_pk(id: $orgId) {
      name
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  private tokenModel: JwtTokenModel;
  private impersonateTokenModel: JwtTokenModel;

  constructor(private jwtHelper: JwtHelperService, private apollo: Apollo) {
    const localStorageToken = localStorage.getItem('token');
    const sessionStorageImpersonateToken = sessionStorage.getItem('impersonateToken');
    if (localStorageToken) {
      this.tokenModel = new JwtTokenModel(localStorageToken);
    }
    if (sessionStorageImpersonateToken) {
      this.impersonateTokenModel = new JwtTokenModel(sessionStorageImpersonateToken);
    }
  }

  public isAuthenticated(): boolean {
    let token: string;
    token = sessionStorage.getItem('impersonateToken');
    if (token) {
      return !this.jwtHelper.isTokenExpired(token);
    } else {
      token = localStorage.getItem('token');
      return !this.jwtHelper.isTokenExpired(token);
    }
  }

  setToken(jwt: string): void {
    const decodedToken = this.jwtHelper.decodeToken(jwt);
    localStorage.setItem('token', jwt);
    localStorage.setItem('token_expiry', decodedToken.exp);
    this.tokenModel = new JwtTokenModel(jwt);
    // commented our for now as it's creating a cyclic dependency
    // this.analytics.run(async () => {
    //   await this.analytics.identifyUser(jwt);
    // });
  }

  setRefreshToken(refreshToken: string): void {
    localStorage.setItem('refresh_token', refreshToken);
  }

  setImpersonateToken(jwt: string): void {
    sessionStorage.setItem('impersonateToken', jwt);
    this.impersonateTokenModel = new JwtTokenModel(jwt);
  }

  deleteToken(): void {
    if (this.isAdminImpersonatingUser()) {
      sessionStorage.removeItem('impersonateToken');
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      sessionStorage.removeItem('impersonateToken');
    }
  }

  getToken(): string {
    if (sessionStorage.getItem('impersonateToken')) {
      return sessionStorage.getItem('impersonateToken');
    }
    return localStorage.getItem('token');
  }

  hasRole(roles: string[]): boolean {
    const currentSession = this.getToken();
    if (!currentSession) {
      return false;
    }

    if (!roles || roles.length <= 0) {
      return true;
    }

    const userDefaultRole = this.getTokenModel().getHasuraDefaultRole();
    return roles.includes(userDefaultRole);
  }

  getUserId(): string {
    return this.getTokenModel().getHasuraUserId();
  }

  getTeamId(): string {
    return this.getTokenModel().getHasuraTeamId();
  }

  getTeamType(): Organisation_Type_Enum {
    return this.getTokenModel().getTeamType();
  }

  getDefaultRole(): JwtHasuraRole {
    const currentSession = this.getToken();
    if (!currentSession) {
      return null;
    }
    return this.getTokenModel().getHasuraDefaultRole();
  }

  async getContactOfCurrentUser(): Promise<
    GetContactOfCurrentUserQuery['user_by_pk']['contacts'][0] | null
  > {
    const userId = this.getUserId();
    const resp = await firstValueFrom(
      this.apollo.query<GetContactOfCurrentUserQuery, GetContactOfCurrentUserQueryVariables>({
        query: GET_CONTACT_OF_CURRENT_USER,
        variables: {
          userId,
        },
      }),
    );
    if (resp?.errors) {
      throw new Error(JSON.stringify(resp.errors, null, 4));
    }
    return resp.data.user_by_pk.contacts?.[0] || null;
  }

  /**
   * Retrieves the member ID of the current user.
   *
   * @returns A Promise that resolves to either a string representing the member ID of the current user
   * or null if the member ID is not available.
   */
  async getMemberIdOfCurrentUser(): Promise<string | null> {
    const resp = await firstValueFrom(
      this.apollo.query<GetMemberOfCurrentUserQuery, GetMemberOfCurrentUserQueryVariables>({
        query: GET_MEMBER_OF_CURRENT_USER,
        variables: {
          userId: this.getUserId(),
          teamId: this.getTeamId()
        },
      }),
    );

    return resp.data.member?.[0]?.id || null;
  }

  getTokenModel(): JwtTokenModel {
    return this.impersonateTokenModel ?? this.tokenModel;
  }

  isAdminImpersonatingUser(): boolean {
    const impersonateToken = sessionStorage.getItem('impersonateToken');
    const token = localStorage.getItem('token');

    return !!(impersonateToken && token);
  }

  getImpersonatedByTokenModel(): JwtTokenModel {
    return this.tokenModel;
  }

  public async getOrgById(orgId: string): Promise<string> {
    const resp = await firstValueFrom(
      this.apollo.query<GetOrgByIdQuery, GetOrgByIdQueryVariables>({
        query: GET_ORG_BY_ID,
        fetchPolicy: 'cache-first',
        variables: {
          orgId,
        },
      }),
    );
    return resp.data.organisation_by_pk.name;
  }

  public getUserRoleOfCurrentTeam(): Member_Role_Enum {
    return this.getTokenModel().getHasuraTeamRole();
  }

  /**
   * Check current login user is buyer or not
   */
  public isBuyerUser(): boolean {
    return this.getUserRoleOfCurrentTeam() === Member_Role_Enum.Buyer;
  }

  /**
   * Check current login user is Admin or not
   */
  public isAdminUser(): boolean {
    return this.getUserRoleOfCurrentTeam() === Member_Role_Enum.Admin;
  }
  /**
   * Check current login user is member or not
   */
  public isMemberUser(): boolean {
    return this.getUserRoleOfCurrentTeam() === Member_Role_Enum.Member;
  }

  /**
   * Check current login user from developer org or not
   */
  public isDeveloperOrgMember(): boolean {
    return this.getTeamType() === Organisation_Type_Enum.Developer;
  }

  /**
   * Check current login user from developer org member user or not
   */
  public isDeveloperOrgMemberUser(): boolean {
    return this.isMemberUser() && this.isDeveloperOrgMember();
  }

  /**
   * Check current login user is Agent Master or not
   */
  public isAgentMasterRole(): boolean {
    return ['agent_admin_master', 'agent_member_master'].includes(this.getDefaultRole());
  }

  /**
   * Check current login user from agent org or not
   */
  public isAgentOrgMember(): boolean {
    return this.getTeamType() === Organisation_Type_Enum.Agent;
  }

  /**
   * Check current login user from agent org admin or not
   */
  public isAgentOrgAdminUser(): boolean {
    return this.isAdminUser() && this.isAgentOrgMember();
  }

  /**
   * Check current login user from agent org admin or not
   */
  public isDeveloperOrgAdminUser(): boolean {
    return this.isAdminUser() && this.isDeveloperOrgMember();
  }

  public async getTeamName(): Promise<string> {
    return await this.getOrgById(this.getTeamId());
  }

  public getUserEmail(): string {
    return this.getTokenModel().getEmail();
  }

  /**
   * Check current login user is Agent Master Admin or not
   */
  public isAgentMasterAdmin(): boolean {
    return ['agent_admin_master'].includes(this.getDefaultRole());
  }
}

const GET_MEMBER_OF_CURRENT_USER = gql`
  query getMemberOfCurrentUser($userId: uuid!, $teamId: uuid!) {
    member(where:{user_id: {_eq: $userId}, organisation_id: {_eq: $teamId}}) {
      id
    }
  }
`;
