import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts amount into Aus currency
 * example: {{listing.price | ausCurrency}} => '$999.75'
 */
@Pipe({
  name: 'ausCurrency',
})
export class AusCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: number | undefined | null): string {
    if (!value) {
      return '$0.00';
    }

    const currency = this.currencyPipe.transform(value, 'AUD', 'symbol-narrow', '1.2-2');

    return currency.replace('.00', '');
  }
}
