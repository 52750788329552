import { Component, Input } from '@angular/core';

@Component({
  selector: 'skychute-spin-loader',
  templateUrl: './spin-loader.component.html',
  styleUrls: ['./spin-loader.component.scss'],
})
export class SpinLoaderComponent {
  @Input() isLoading = false;
  @Input() isLoaderFinished = false;
}
