import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { take } from 'rxjs/operators';
import { BasicDialogComponent } from '../basic-dialog/basic-dialog.component';
import { ScreenLoaderComponent } from '../screen-loader/screen-loader.component';
import { SvgLoaderComponent } from '../svg-loader/svg-loader.component';
import { ViewportService } from '@skychute/shared-services';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(public dialog: MatDialog, private viewportService: ViewportService) {
  }

  confirmRef: MatDialogRef<BasicDialogComponent>;
  loaderRef: MatDialogRef<ScreenLoaderComponent>;
  svgLoaderRef: MatDialogRef<SvgLoaderComponent>;
  isDialogOpened = false;
  isSvgDialogOpened = false;

  openConfirm(config: OpenConfirmInterface): Promise<{ confirmed: boolean; text: string }> {
    config.title = config.title ?? 'Confirm';
    config.text = config.text ?? '';
    config.okBtnText = config.okBtnText ?? 'Ok';
    config.cancelBtnText = config.cancelBtnText ?? 'Cancel';
    config.hideCancel = config.hideCancel ?? false;
    config.allowClose = config.allowClose ?? true;
    config.disableClose = config.disableClose ?? false;
    config.width = config?.width ?? '480px';
    config.captureText = config?.captureText ?? false;
    config.textInputLabel = config?.textInputLabel ?? 'Input';
    config.textInputPlaceholder = config?.textInputPlaceholder ?? '...';
    config.textInputValue = config?.textInputValue ?? ' ';
    config.textInputValueRequired = config?.textInputValueRequired ?? true;

    return new Promise((resolve) => {
      this.confirmRef = this.dialog.open(BasicDialogComponent, {
        width: config.width,
        disableClose: config.disableClose,
        data: {
          okBtnText: config.okBtnText,
          title: config.title,
          text: config.text,
          cancelBtnText: config.cancelBtnText,
          hideCancel: config.hideCancel,
          allowClose: config.allowClose,
          captureText: config.captureText,
          textInputLabel: config.textInputLabel,
          textInputPlaceholder: config.textInputPlaceholder,
          textInputValue: config.textInputValue,
          textInputValueRequired: config.textInputValueRequired,
        },
      });

      this.confirmRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((result) => {
          resolve(result);
        });
    });
  }

  closeConfirm(): void {
    if (this.confirmRef) {
      this.confirmRef.close();
    }
  }

  showAlert(title: string, text: string, okBtnText = 'Ok'): Promise<boolean> {
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(BasicDialogComponent, {
        width: '480px',
        data: {
          okBtnText,
          title,
          text,
          hideCancel: true,
          allowClose: true,
        },
      });

      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((result: boolean) => {
          resolve(result);
        });
    });
  }

  showAlertAndGetRef(
    title: string,
    text: string,
    okBtnText = 'Ok',
  ): MatDialogRef<BasicDialogComponent> {
    return this.dialog.open(BasicDialogComponent, {
      width: '480px',
      data: {
        okBtnText,
        title,
        text,
        hideCancel: true,
        allowClose: true,
      },
    });
  }

  openFullWidthDialog<T, D, R>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    desktopWidth = '1139px',
    data: D = null,
    config: MatDialogConfig = {},
  ): MatDialogRef<T, R> {
    const dialogConfig: MatDialogConfig = {
      width: desktopWidth,
      panelClass: 'no-padding-dialog',
      data,
      ...config,
    };

    if (this.viewportService.isLtMd()) {
      dialogConfig['width'] = '100%';
      dialogConfig['height'] = '100%';
      dialogConfig['maxWidth'] = '100%';
      dialogConfig['maxHeight'] = '100%';
    } else {
      dialogConfig['height'] = '100%';
    }

    return this.dialog.open(componentOrTemplateRef, dialogConfig);
  }

  emptyFullScreenDialog<T, R>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    data,
    config: MatDialogConfig = {},
  ): MatDialogRef<T, R> {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'empty-full-width-dialog',
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      hasBackdrop: false, // it's a full screen, why backdrop?
      data,
      ...config,
    };
    return this.dialog.open(componentOrTemplateRef, dialogConfig);
  }

  showLoading(title: string, content = ''): MatDialogRef<ScreenLoaderComponent> {
    if (this.loaderRef && this.isDialogOpened) {
      this.loaderRef.componentInstance.title = title;
      this.loaderRef.componentInstance.content = content;
    } else {
      this.isDialogOpened = true;
      this.loaderRef = this.dialog.open(ScreenLoaderComponent, {
        width: '480px',
        data: {
          title,
          content,
        },
        disableClose: true,
      });
      this.loaderRef
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          this.isDialogOpened = false;
          this.loaderRef = null;
        });
    }
    return this.loaderRef;
  }

  hideLoading(): void {
    if (this.loaderRef) {
      this.loaderRef.close();
    }
  }

  open<T, R>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    dialogConfig: MatDialogConfig = {},
  ): MatDialogRef<T, R> {
    return this.dialog.open(componentOrTemplateRef, dialogConfig);
  }

  showSvgLoading(title: string, content = ''): MatDialogRef<SvgLoaderComponent> {
    if (this.svgLoaderRef && this.isSvgDialogOpened) {
      this.svgLoaderRef.componentInstance.title = title;
      this.svgLoaderRef.componentInstance.content = content;
    } else {
      this.isSvgDialogOpened = true;
      this.svgLoaderRef = this.dialog.open(SvgLoaderComponent, {
        width: '480px',
        data: {
          title,
          content,
        },
        disableClose: true,
      });
      this.svgLoaderRef
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          this.isSvgDialogOpened = false;
          this.svgLoaderRef = null;
        });
    }
    return this.svgLoaderRef;
  }

  hideSvgLoading(): void {
    if (this.svgLoaderRef) {
      this.svgLoaderRef.close();
    }
  }
}

export interface OpenConfirmInterface {
  title?: string;
  text?: string;
  okBtnText?: string;
  cancelBtnText?: string;
  hideCancel?: boolean;
  allowClose?: boolean;
  disableClose?: boolean;
  width?: string; // ex.: 480px
  captureText?: boolean;
  textInputLabel?: string;
  textInputPlaceholder?: string;
  textInputValue?: string;
  textInputValueRequired?: boolean;
}
