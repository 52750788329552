// sorting on any kind of array
// default sort in ascending direction
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function numericSort(items: any, key: string, mode: 'asc' | 'dec' = 'asc'): any {
  if (!Array.isArray(items)) {
    throw 'Argument must be an array.';
  }
  if (!key) {
    throw 'key is required';
  }

  return items.sort((a, b) => {
    const first = mode === 'asc' ? a : b;
    const last = mode === 'asc' ? b : a;
    return String(first[key]).localeCompare(String(last[key]), 'en', { numeric: true });
  });
}
