import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogService } from '@skychute/ui/dialog';

interface FileSystemEntry {
  name: string;
  isDirectory: boolean;
  isFile: boolean;
}

class NgxFileDropEntry {
  constructor(public relativePath: string, public fileEntry: FileSystemEntry) {}
}

@Component({
  selector: 'skychute-file-upload-control',
  templateUrl: './file-upload-control.component.html',
  styleUrls: ['./file-upload-control.component.scss'],
})
export class FileUploadControlComponent implements OnDestroy {
  @ViewChild('fileSelector', { static: true })
  public fileSelector: ElementRef;

  @Output()
  fileAdded: EventEmitter<File> = new EventEmitter<File>();

  @Output()
  fileOver: EventEmitter<any> = new EventEmitter();

  @Output()
  fileLeave: EventEmitter<any> = new EventEmitter();

  @Input()
  disabled: boolean;

  @Input()
  label: string;

  @Input()
  caption: string;

  @Input() set accept(value: string) {
    this._accept = value;
    if (this._accept) {
      // str like: image/png,image/jpeg,application/pdf,image/heic
      this.acceptedFileFormats = this._accept
        .replace(/image\//gi, '') // becomes png,jpeg,application/pdf,heic
        .replace(/application\//gi, '') // becomes "png,jpeg,pdf,heic"
        .split(',') // ["png", "jpeg", "pdf", "heic"]
        .map((ext) => ext.toUpperCase()) // ["PNG", "JPEG", "PDF", "HEIC"]
        .join(', '); // "PNG, JPEG, PDF, HEIC"
    }
  }

  _accept: string;
  isDraggingOverDropZone = false;
  acceptedFileFormats: string;

  private globalDragStartListener: () => void;
  private globalDragEndListener: () => void;
  private globalDraggingInProgress = false;
  private dropEventTimerSubscription: Subscription | null = null;
  private files: NgxFileDropEntry[] = [];

  constructor(private renderer: Renderer2, private dialogService: DialogService) {
    this.globalDragStartListener = this.renderer.listen('document', 'dragstart', () => {
      this.globalDraggingInProgress = true;
    });
    this.globalDragEndListener = this.renderer.listen('document', 'dragend', () => {
      this.globalDraggingInProgress = false;
    });
  }

  ngOnDestroy(): void {
    if (this.dropEventTimerSubscription) {
      this.dropEventTimerSubscription.unsubscribe();
      this.dropEventTimerSubscription = null;
    }
    this.globalDragStartListener();
    this.globalDragEndListener();
    this.files = [];
  }

  onDragOver(event: Event): void {
    if (!this.isDropzoneDisabled()) {
      if (!this.isDraggingOverDropZone) {
        this.isDraggingOverDropZone = true;
        this.fileOver.emit(event);
      }
      this.preventAndStop(event);
    }
  }

  onDragLeave(event: Event): void {
    if (!this.isDropzoneDisabled()) {
      if (this.isDraggingOverDropZone) {
        this.isDraggingOverDropZone = false;
        this.fileLeave.emit(event);
      }
      this.preventAndStop(event);
    }
  }

  private isDropzoneDisabled(): boolean {
    return this.globalDraggingInProgress || this.disabled;
  }

  async dropFiles(event: DragEvent): Promise<void> {
    this.preventAndStop(event);

    this.isDraggingOverDropZone = false;
    try {
      if (event.dataTransfer) {
        event.dataTransfer.dropEffect = 'copy';
        let items: FileList | DataTransferItemList;
        let item: DataTransferItem | File;

        if (event.dataTransfer.items) {
          items = event.dataTransfer.items as DataTransferItemList;
          item = items[0] as DataTransferItem;
        } else {
          items = event.dataTransfer.files as FileList;
          item = items[0] as File;
        }

        if (this.dropEventTimerSubscription) {
          this.dropEventTimerSubscription.unsubscribe();
        }
        const file = item as DataTransferItem;
        if (file && !!file.type) {
          if (
            this._accept &&
            !this._accept
              .split(',')
              .map((o) => o.trim())
              .includes(file.type)
          ) {
            await this.dialogService.showAlert(
              'Warning!',
              'You have uploaded an invalid file type',
            );
            this.fileSelector.nativeElement.value = '';
            return;
          }
          return this.fileAdded.emit(file.getAsFile());
        }
        await this.dialogService.showAlert('Warning!', 'Unsupported extension');
      }
    } finally {
      this.fileSelector.nativeElement.value = '';
    }
  }

  private preventAndStop(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }

  public async uploadFiles(event: Event): Promise<void> {
    if (event.target) {
      const items = (event.target as HTMLInputElement).files || ([] as any);
      const file = items[0];
      if (
        this._accept &&
        !this._accept
          .split(',')
          .map((o) => o.trim())
          .includes(file.type)
      ) {
        await this.dialogService.showAlert('Warning!', 'You have uploaded an invalid file type');
        this.fileSelector.nativeElement.value = '';
        return;
      }
      this.fileAdded.emit(items[0]);
    }
    this.fileSelector.nativeElement.value = '';
  }
}
