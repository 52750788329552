import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import {
  GetAddressDetailsQuery,
  GetAddressDetailsQueryVariables,
  GetAutoCompleteQuery,
  GetAutoCompleteQueryVariables,
  GetCountriesQuery,
  GetCountriesQueryVariables,
} from '../../generated/operations';
import { firstValueFrom, Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(private apollo: Apollo) {}

  /**
   * Returns auto-complete options for given addressText
   * @deprecated by Pavel, as it's not currently used anywhere (probably used by frontend directly)
   */
  async getAutoComplete(
    addressText: string,
  ): Promise<GetAutoCompleteQuery['address_auto_complete']> {
    const resp = await firstValueFrom(
      this.apollo.query<GetAutoCompleteQuery, GetAutoCompleteQueryVariables>({
        query: GET_ADDRESS_AUTO_COMPLETE,
        variables: {
          addressInput: addressText,
        },
      }),
    );
    return resp.data?.address_auto_complete;
  }

  /**
   * Retrieves detailed information for a given address.
   *
   * @deprecated by Pavel, as it's not currently used anywhere (probably used by frontend directly)
   * @param {string} addressId - The ID of the address for which to retrieve details.
   * @returns {Promise<GetAddressDetailsQuery['get_address_detail']>} - A promise that resolves to the detailed address information.
   */
  async getAddressDetail(addressId: string): Promise<GetAddressDetailsQuery['get_address_detail']> {
    const resp = await firstValueFrom(
      this.apollo.query<GetAddressDetailsQuery, GetAddressDetailsQueryVariables>({
        query: GET_ADDRESS_DETAILS_QUERY,
        variables: {
          addressId,
        },
      }),
    );
    return resp.data?.get_address_detail;
  }

  getCountries(): Observable<ApolloQueryResult<GetCountriesQuery>> {
    return this.apollo.query<GetCountriesQuery, GetCountriesQueryVariables>({
      query: GET_COUNTRIES,
    });
  }
}

const GET_ADDRESS_AUTO_COMPLETE = gql`
  query getAutoComplete($addressInput: String!) {
    address_auto_complete(address: { addressInput: $addressInput }) {
      completions {
        id
        canonical_address_id
        full_address
        highlighted_full_address
      }
    }
  }
`;

const GET_ADDRESS_DETAILS_QUERY = gql`
  query getAddressDetails($addressId: String!) {
    get_address_detail(address: { addressId: $addressId }) {
      id
      address_line_1
      full_address
      locality_name
      lot_identifier
      postcode
      state_territory
      street
      street_number_1
      unit_identifier
    }
  }
`;

const GET_COUNTRIES = gql`
  query getCountries @cached(ttl: 300) {
    country {
      value
    }
  }
`;
