import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { JwtService } from '@skychute/jwt';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(public router: Router, private jwtService: JwtService) {}

  async canActivate(): Promise<boolean> {
    const userDefaultRole = this.jwtService.getDefaultRole();
    if (userDefaultRole === 'staff_admin') {
      // staff_admin can able to access admin page
      return true;
    }
    this.router.navigate(['/pages/projects']);
    return false;
  }
}
