<div class="property-loader" *ngIf="!isVerticle">
  <div>
    <img class="property-loader-image_sm property-loader-image_md" src="/assets/property-loader.gif" alt="loader"/>
  </div>
  <div class="property-loader__detail property-loader_content_spacing">
    <h3>{{ title }}</h3>
    <div class="property-loader_content">
      <span>
        {{ description }}
      </span>
      <span>{{ subDescription }}</span>
    </div>
  </div>
</div>

<div *ngIf="isVerticle" class="property-loader__content">
  <div>
    <img class="property-loader-image_md" src="/assets/property-loader.gif" alt="loader"/>
  </div>
  <div class="property-loader_content_spacing">
    <h3>{{ title }}</h3>
    <div class="property-loader_content">
      <span>
        {{ description }}
      </span>
      <span>{{ subDescription }}</span>
    </div>
  </div>
</div>
