import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'skychute-screen-alert',
  templateUrl: './screen-alert.component.html',
  styleUrls: ['./screen-alert.component.scss'],
})
export class ScreenAlertComponent {
  @Input()
  title: string;

  @Input()
  actionText: string;

  @Input()
  actionRouterLink: string;

  @Output()
  actionClick: EventEmitter<any> = new EventEmitter();
}
