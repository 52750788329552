import { Component, Input } from '@angular/core';

@Component({
  selector: 'skychute-property-loader',
  templateUrl: './property-loader.component.html',
  styleUrls: ['./property-loader.component.scss'],
})
export class PropertyLoaderComponent {
  @Input()
  isVerticle: boolean;

  title = 'Processing...';
  description =
    'The information is being synced with your Sales database and may take a few minutes.';
  subDescription = 'You can wait or leave this page, it will not interrupt the process.';
}
