
/* ATTENTION!
This file is automatically generated by scripts/set-env.ts
Please do not change or commit it
*/
import { IEnvironment } from '@skychute/shared-models';
function isEqual(a: any, b: any): boolean {
    return a === b;
}
export const config: IEnvironment = {
    production: isEqual('false', 'true'),
    services: {
        data: {
          endpoint: 'https://dev-hasura.projectre.com.au:8090',
          path: '/v1/graphql',
          logQueries: 'false'
        },
        aws: {
          UserPoolId: 'ap-southeast-2_cMUJtVWZI',
          AppClientId: '2bibev4uh0uco1jnsroo05cv2r',
          cloudUri: 'https://z1gczacwo2.execute-api.ap-southeast-2.amazonaws.com/dev',
        },
        analytics: {
            segment: {
                enabled: isEqual('false', 'true'),
                writeKey: 'CKazVWLN9swSOQAxq5aOOuUzu9MFuARF',
            },
        },
    },
    application: {
        version: '1.49.0',
        gitHead: 'undefined',
        branch: 'development',
        buildDate: 'March 17th 2023, 7:06:23 pm',
        buildNumber: 'undefined',
    },
    stripe: {
        publicKey: 'pk_test_51JVQJzICXqe0hIJdVHDRyFoCVJbQwhWPayB7eHg1boYOy7FsCv7Sj1CJSYA2u6vPj4zRNbmsR6tsVu48e0yQEhYT00ycLtgphf'
    },
    addressFinder: {
        key: 'RBHCYUV987D4XKNPT6FL',
        src: 'https://api.addressfinder.io/assets/v3/widget.js'
    },
    courier_push_client_key:'ODM2OTc0NWItODA5NS00OTI3LWE1ZTEtMWI0OGEyZjBkNjg0L3Rlc3Q=',
};
