import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

/**
 * Usage: {{str | timeAgo}}
 */
@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: string): string {
    if (value === undefined || value === null || !value) {
      return '';
    }

    return moment(value).fromNow();
  }
}
