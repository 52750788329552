import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clean',
})
export class CleanValuePipe implements PipeTransform {
  transform(value: any): any {
    if (typeof value === 'string' && value) {
      return value;
    } else if (typeof value === 'string' && !value) {
      return '—';
    }

    if (value === undefined || value === null) {
      return '—';
    } else {
      return value;
    }
  }
}
