import { Injectable } from '@angular/core';
import { JwtService } from '@skychute/jwt';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HubSpotTrackingService {
  hubSpotScriptId = 'hs-script-loader';
  hubSpotPortalId = '44862234';
  isScriptInjected = false;
  eventUrl = 'https://api.hubspot.com/events/v3/send';


  constructor(private jwt: JwtService, private http: HttpClient) {
  }

  /**
   * This will add a new <script> tag for HubSpot Tracking if it's not already present
   * Returns promise which will be resolved when all JS files of HubSpot Tracking are loaded
   */
  injectSegmentScriptTag(callback: () => void = null): void {
    try {
      if (this.isScriptInjected || this.isHubSpotTrackingScriptInjected()) {
        return;
      }
      // type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/{hubId}.js"
      const hubSpotScriptElement = document.createElement('script');
      hubSpotScriptElement.setAttribute('id', this.hubSpotScriptId);
      hubSpotScriptElement.setAttribute('type', 'text/javascript');
      hubSpotScriptElement.setAttribute('src', `//js.hs-scripts.com/${this.hubSpotPortalId}.js`);
      document.body.appendChild(hubSpotScriptElement);
      this.isScriptInjected = true;
      if (callback) {
        callback();
      }
    } catch (err) {
      console.error(err);
    }
  }

  isHubSpotTrackingScriptInjected(): boolean {
    return !!document.querySelector(`#${this.hubSpotScriptId}`);
  }

  identifyUser(): void {
    const email = this.jwt.getUserEmail();
    const _hsq = window['_hsq'] || [];
    _hsq.push(['identify', {
      email,
    }]);
    this.injectSegmentScriptTag();
    console.log('HubSpotTrackingService.identifyUser()');
  }

  trackPageView(path: string): void {
    const _hsq = window['_hsq'] || [];
    _hsq.push(['setPath', path]);
    _hsq.push(['trackPageView']);
    this.injectSegmentScriptTag();
    console.log(`HubSpotTrackingService.trackPageView(${path})`);
  }

  async viewedProject(projectName: string): Promise<void> {
    // const _hsq = window['_hsq'] || [];
    // console.log(window['_hsq']);
    const eventPayload = {
      name: 'pe44862234_viewed_project',
      properties: {
        project_name: projectName,
      },
    };
    console.log(eventPayload);
    // _hsq.push([
    //   'trackCustomBehavioralEvent',
    //   eventPayload,
    // ]);
  }
}
