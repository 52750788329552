<div class="alert alert__{{type}}" *ngIf="message"
     [ngClass]="{'alert--no-content': ref.children.length === 0}">
  <div class="alert__message">
    <mat-icon class="material-icons-round">{{type === 'success' ? 'check_circle' : type}}</mat-icon>
    <p id="alert__message">{{message}}</p>
  </div>
  <div #ref class="alert__content">
    <ng-content></ng-content>
  </div>
</div>
