// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { config } from './environment.config';
import { IEnvironment } from '@skychute/shared-models';

const environment: IEnvironment = {
  production: false,
  stripe: {
    publicKey: '',
  },
  addressFinder: { key: '', src: '' },
  courier_push_client_key: '',
};

for (const key of Object.keys(config)) {
  environment[key] = config[key];
}
export { environment };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
