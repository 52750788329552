import { Component, Inject } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface BasicDialogData {
  title: string;
  text: SafeHtml | string;
  okBtnText?: string;
  cancelBtnText?: string;
  hideCancel?: boolean;
  allowClose?: boolean;
  captureText?: boolean;
  textInputLabel?: string;
  textInputPlaceholder?: string;
  textInputValue?: string;
  textInputValueRequired?: boolean;
}

@Component({
  selector: 'skychute-basic-dialog',
  templateUrl: './basic-dialog.component.html',
  styleUrls: ['./basic-dialog.component.scss'],
})
export class BasicDialogComponent {
  form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<BasicDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BasicDialogData,
  ) {
    if (data?.captureText) {
      this.form = new UntypedFormGroup({
        text: new UntypedFormControl(' ', []),
      });
      if (data?.textInputValueRequired === true) {
        this.form.controls.text.addValidators(Validators.required);
      } else {
      }
      if (data?.textInputValue) {
        this.form.controls.text.setValue(data.textInputValue);
      }
    }
  }

  closeDialog(result: boolean): void {
    if (this.data.allowClose === false) {
      return;
    }

    // Cancel
    if (!result) {
      this.dialogRef.close({
        confirmed: false,
        text: '',
      });
      return;
    }

    // Ok

    // no form
    if (!this.form) {
      this.dialogRef.close({
        confirmed: true,
        text: '',
      });
      return;
    }

    // form invalid
    if (!this.form.valid) {
      return;
    }

    // form valid
    this.dialogRef.close({
      confirmed: true,
      text: this.form.get('text').value,
    });
  }
}
