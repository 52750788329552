import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Deal_Status_Enum } from '@skychute/schema';

@Component({
  selector: 'skychute-deal-status',
  templateUrl: './deal-status.component.html',
  styleUrls: ['./deal-status.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DealStatusComponent {
  statusText: string;
  statusClass: string;

  @Input() suffix = '';
  @Input() id = '';
  @Input() set status(status: Deal_Status_Enum) {
    switch (status) {
      //
      // Yellow
      //
      case Deal_Status_Enum.OnHold:
        this.statusText = 'On Hold';
        this.statusClass = 'deal-status--yellow';
        break;
      case Deal_Status_Enum.Reserved:
        this.statusText = 'Reserved';
        this.statusClass = 'deal-status--yellow';
        break;
      case Deal_Status_Enum.Issued:
        this.statusText = 'Issued';
        this.statusClass = 'deal-status--yellow';
        break;
      case Deal_Status_Enum.Active:
        this.statusText = 'Active';
        this.statusClass = 'deal-status--yellow';
        break;
      case Deal_Status_Enum.ContractIssued:
        this.statusText = 'Contract Issued';
        this.statusClass = 'deal-status--yellow';
        break;
      case Deal_Status_Enum.Enquiry:
        this.statusText = 'Enquiry';
        this.statusClass = 'deal-status--yellow';
        break;
      case Deal_Status_Enum.Lead:
        this.statusText = 'Lead';
        this.statusClass = 'deal-status--yellow';
        break;
      case Deal_Status_Enum.Pending:
        this.statusText = 'Pending';
        this.statusClass = 'deal-status--yellow';
        break;
      case Deal_Status_Enum.SaReceived:
        this.statusText = 'SA Received';
        this.statusClass = 'deal-status--yellow';
        break;
      case Deal_Status_Enum.SaSentToLegal:
        this.statusText = 'SA Sent To Legal';
        this.statusClass = 'deal-status--yellow';
        break;
      case Deal_Status_Enum.Won:
        this.statusText = 'Won';
        this.statusClass = 'deal-status--yellow';
        break;
      //
      // Orange
      //
      case Deal_Status_Enum.Conditional:
        this.statusText = 'Conditional';
        this.statusClass = 'deal-status--orange';
        break;
      case Deal_Status_Enum.Deposit:
        this.statusText = 'Deposit';
        this.statusClass = 'deal-status--orange';
        break;
      case Deal_Status_Enum.Eoi:
        this.statusText = 'EOI';
        this.statusClass = 'deal-status--orange';
        break;
      case Deal_Status_Enum.Offer:
        this.statusText = 'Offer';
        this.statusClass = 'deal-status--orange';
        break;
      //
      // Error
      //
      case Deal_Status_Enum.Exchanged:
        this.statusText = 'Exchanged';
        this.statusClass = 'deal-status--error';
        break;
      case Deal_Status_Enum.Handover:
        this.statusText = 'Handover';
        this.statusClass = 'deal-status--error';
        break;
      case Deal_Status_Enum.Settled:
        this.statusText = 'Settled';
        this.statusClass = 'deal-status--primary';
        break;
      case Deal_Status_Enum.Unconditional:
        this.statusText = 'Unconditional';
        this.statusClass = 'deal-status--error';
        break;
      //
      // Accent
      //
      case Deal_Status_Enum.Cancelled:
        this.statusText = 'Cancelled';
        this.statusClass = 'deal-status--accent';
        break;
      case Deal_Status_Enum.Lost:
        this.statusText = 'Lost';
        this.statusClass = 'deal-status--accent';
        break;
      case Deal_Status_Enum.Rescission:
        this.statusText = 'Rescission';
        this.statusClass = 'deal-status--accent';
        break;
      case Deal_Status_Enum.Reservationcancelled:
        this.statusText = 'Reservation Cancelled';
        this.statusClass = 'deal-status--accent';
        this.statusClass = 'deal-status--accent';
        break;
      case Deal_Status_Enum.Termination:
        this.statusText = 'Termination';
        this.statusClass = 'deal-status--accent';
        break;
      //
      // Grey
      //
      case Deal_Status_Enum.BaApproved:
        this.statusText = 'BA Approved';
        this.statusClass = 'deal-status--grey';
        break;
      case Deal_Status_Enum.BaLodged:
        this.statusText = 'BA Lodged';
        this.statusClass = 'deal-status--grey';
        break;
      case Deal_Status_Enum.ConstructionEnclosed:
        this.statusText = 'Construction Enclosed';
        this.statusClass = 'deal-status--grey';
        break;
      case Deal_Status_Enum.ConstructionFixing:
        this.statusText = 'Construction Fixing';
        this.statusClass = 'deal-status--grey';
        break;
      case Deal_Status_Enum.ConstructionFrame:
        this.statusText = 'Construction Frame';
        this.statusClass = 'deal-status--grey';
        break;
      case Deal_Status_Enum.ConstructionPracticalCompletion:
        this.statusText = 'Construction Practical Completion';
        this.statusClass = 'deal-status--grey';
        break;
      case Deal_Status_Enum.ConstructionSite:
        this.statusText = 'Construction Site';
        this.statusClass = 'deal-status--grey';
        break;
      case Deal_Status_Enum.ConstructionSlab:
        this.statusText = 'Construction Slab';
        this.statusClass = 'deal-status--grey';
        break;
      case Deal_Status_Enum.Contracted:
        this.statusText = 'Contracted';
        this.statusClass = 'deal-status--grey';
        break;
      //
      // Default: Grey
      //
      default:
        this.statusText = status;
        this.statusClass = 'deal-status--grey';
    }
  }
}
