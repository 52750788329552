import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  static isLoading = new Subject<boolean>();
  static loadingText = new Subject<string>();
  // global progress loader at the top of the page
  isBarLoaderVisible = new Subject<boolean>();

  startBarLoader(): void {
    this.isBarLoaderVisible.next(true);
  }

  stopBarLoader(): void {
    this.isBarLoaderVisible.next(false);
  }

  static start(): void {
    this.isLoading.next(true);
  }

  static setLoaderMessage(text: string): void {
    this.loadingText.next(text);
  }

  static stop(): void {
    this.isLoading.next(false);
  }
}

export function startLoader(text?: string): boolean {
  // return loading state
  LoaderService.start();
  // Set Loader text if exist
  if (text) {
    setTimeout(() => {
      LoaderService.setLoaderMessage(text);
    }, 10);
  }
  return true;
}

export function stopLoader(): boolean {
  // Reset text
  LoaderService.setLoaderMessage('');
  // return loading state
  LoaderService.stop();
  return false;
}

export function setLoaderMessage(text: string): void {
  LoaderService.setLoaderMessage(text);
}
