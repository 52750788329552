import { Injectable } from '@angular/core';
import { ViewportBreakpoint } from './types/viewport-breakpoint.type';
import { ViewportBreakpointInterface } from './interfaces/viewport-breakpoint.interface';

@Injectable({
  providedIn: 'root',
})
export class ViewportService {
  // re-using the same breakpoints we have in angular-flex
  breakpoints: { [key in ViewportBreakpoint]: ViewportBreakpointInterface } = {
    xs: {
      begin: 0,
      end: 599.9,
    },
    sm: {
      begin: 600,
      end: 959.9,
    },
    md: {
      begin: 960,
      end: 1279.9,
    },
    lg: {
      begin: 1280,
      end: 1919.9,
    },
    xl: {
      begin: 1920,
      end: 4999.99,
    },
  };

  overlappingGt = {
    gtXs: 600,
    gtSm: 960,
    gtMd: 1280,
    gtLg: 1920,
  };

  overlappingLt = {
    ltSm: 599.9,
    ltMd: 959.9,
    ltLg: 1279.9,
    ltXl: 1919.9,
  };

  isXs(): boolean {
    return (
      window.innerWidth > this.breakpoints.xs.begin && window.innerWidth <= this.breakpoints.xs.end
    );
  }

  isSm(): boolean {
    return (
      window.innerWidth > this.breakpoints.sm.begin && window.innerWidth <= this.breakpoints.sm.end
    );
  }

  isLtMd(): boolean {
    return this.ltLayoutBp('ltMd');
  }

  isGtSm(): boolean {
    return this.gtLayoutBp('gtSm');
  }

  ltLayoutBp(bp: 'ltSm' | 'ltMd' | ' ltLg' | 'ltXl'): boolean {
    return window.innerWidth <= this.overlappingLt[bp];
  }

  gtLayoutBp(bp: 'gtXs' | 'gtSm' | ' gtMd' | 'gtLg'): boolean {
    return window.innerWidth >= this.overlappingGt[bp];
  }
}
