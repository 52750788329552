import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

/**
 * Usage: {{str | dateformat}}
 */
@Pipe({
  name: 'dateformat',
})
export class DateFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (value === undefined || value === null || !value) {
      return '';
    }

    return moment(value).format('DD/MM/YYYY hh:mm a');
  }
}
