import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts "ON_HOLD" to "ON HOLD"
 * Usage: {{item.name | enum}}
 */
@Pipe({ name: 'enum' })
export class EnumPipe implements PipeTransform {
  transform(input: string): string {
    return input
      .toString()
      .toUpperCase()
      .replace(/IN_PROCESS/gi, 'IN_PROGRESS')
      .replace(/_+/g, ' '); // Replace "_" with " "
  }
}
