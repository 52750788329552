import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencySuffix',
})
export class CurrencySuffixPipe implements PipeTransform {
  transform(input: any, args?: any): any {
    let exp;
    const suffixes = ['K', 'M', 'B', 'T', 'P', 'E'];
    const isNegativeValues = input < 0;
    if (
      Number.isNaN(input) ||
      (input < 1000 && input >= 0) ||
      !this.isNumeric(input) ||
      (input < 0 && input > -1000)
    ) {
      if (!!args && this.isNumeric(input) && !(input < 0) && input !== 0) {
        return input.toFixed(args);
      } else {
        return input;
      }
    }

    if (!isNegativeValues) {
      exp = Math.floor(Math.log(input) / Math.log(1000));
      if (exp === 1) {
        return Math.round(input / Math.pow(1000, exp)) + suffixes[exp - 1];
      }
      let shortenedNumber = (input / Math.pow(1000, exp)).toFixed(args).toString();
      let count = 10;
      while (count > 0) {
        const lastCharIndex = shortenedNumber.length - 1;
        // Remove zeroes after "." iterating from the end of the string
        if (shortenedNumber[lastCharIndex] === '0') {
          shortenedNumber = shortenedNumber.slice(0, -1);
          // Remove "." and stop when reaching it
        } else if (shortenedNumber[lastCharIndex] === '.') {
          shortenedNumber = shortenedNumber.slice(0, -1);
          break;
          // When hitting a non zero number after floating point stop so the result can look like this "20.3M"
        } else {
          break;
        }
        count--;
      }
      return shortenedNumber + suffixes[exp - 1];
    } else {
      input = input * -1;
      exp = Math.floor(Math.log(input) / Math.log(1000));
      return ((input * -1) / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];
    }
  }
  isNumeric(value): boolean {
    let input = value;
    if (input < 0) input = input * -1;
    if (/^-{0,1}\d+$/.test(input)) {
      return true;
    } else return /^\d+\.\d+$/.test(input);
  }
}
