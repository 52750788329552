<div class="uploaded-document" *ngIf="myData?.name">
  <ng-container *ngIf="isLoading">
    <div class="uploaded-document__loading">
      <mat-progress-spinner mode="determinate"
                            [value]="myData.uploadProgress"
                            diameter="30"></mat-progress-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!isLoading">
    <!-- image -->
    <a *ngIf="myData?.content_type !== 'application/pdf' && myData.content_type !== 'image/heic'"
       [href]="downloadUrl"
       target="_blank"
       class="uploaded-document__image"
       [style.background-image]="'url(' + downloadUrl  + ')'"></a>
    <!-- heic -->
    <a *ngIf="myData.content_type === 'image/heic'"
       [href]="downloadUrl"
       target="_blank"
       class="uploaded-document__image"
       style="background-image: url(/assets/heic-icon.svg);"></a>
    <!-- PDF -->
    <a *ngIf="myData?.content_type === 'application/pdf'"
       target="_blank"
       [href]="downloadUrl"
       class="uploaded-document__image"
       style="background-image: url(/assets/pdf-icon.svg);"
    ></a>
  </ng-container>
  <!-- name -->
  <div class="uploaded-document__name fig-body-2">
    <a *ngIf="!isLoading" [href]="downloadUrl" target="_blank"
       [title]="myData?.name">{{ myData.name }}</a>
    <span *ngIf="isLoading">{{myData.name}}</span>
  </div>
  <!-- delete button -->
  <div class="uploaded-document__button"
       *ngIf="(!isReadOnly && myData?.attachment_type!=='ID_VERIFICATION_REPORT')"
       (click)="delete(myData)">
    <img src="/assets/delete-icon.svg" alt="delete" width="16" />
    <span class="fig-caption">Delete</span>
  </div>
</div>
