import { Pipe, PipeTransform } from '@angular/core';
import { numericSort } from '../functions/numeric-sort.function';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(value: Array<any>, key: string, mode: 'asc' | 'dec' = 'asc'): unknown {
    if (!key) {
      return value;
    }
    return numericSort(value, key, mode);
  }
}
