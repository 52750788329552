import { Component, Input, ViewEncapsulation } from '@angular/core';
import { SkychuteAlertType } from '../model/alert.type';

@Component({
  selector: 'skychute-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AlertComponent {
  @Input() message = '';
  @Input() type: SkychuteAlertType = 'success';
}
