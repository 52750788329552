<div class="basic-dialog">
  <h1 class="headline-4" matDialogTitle id="basic-dialog__title">{{data.title}}</h1>
  <div [innerHTML]="data.text" class="mat-body-1" mat-dialog-content></div>
  <form *ngIf="form" [formGroup]="form">
    <mat-form-field class="basic-dialog__text" appearance="outline">
      <mat-label>{{data.textInputLabel}}</mat-label>
      <textarea formControlName="text" matInput [placeholder]="data.textInputPlaceholder">
      </textarea>
      <mat-error *ngIf="form.controls.text.errors?.required">This field is required</mat-error>
    </mat-form-field>
  </form>
  <div class="basic-dialog__footer">
    <button mat-stroked-button
            (click)="closeDialog(false)"
            *ngIf="!data.hideCancel">
      {{data?.cancelBtnText || 'Cancel'}}
    </button>
    <button mat-flat-button
            color="primary"
            id="basic-dialog__button-ok"
            (click)="closeDialog(true)">
      {{data?.okBtnText || 'OK'}}
    </button>
  </div>
</div>
