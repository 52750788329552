import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Deal_Deposit_Status_Enum } from '@skychute/schema';

@Component({
  selector: 'skychute-deal-deposit-status',
  templateUrl: './deal-deposit-status.component.html',
  styleUrls: ['./deal-deposit-status.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DealDepositStatusComponent {
  statusText: string;
  statusClass: string;

  @Input() set status(status: Deal_Deposit_Status_Enum) {
    switch (status) {
      //
      // Yellow
      //
      case Deal_Deposit_Status_Enum.InProcess:
        this.statusText = 'In Progress';
        this.statusClass = 'deal-deposit-status--yellow';
        break;
      case Deal_Deposit_Status_Enum.Recheck:
        this.statusText = 'Recheck';
        this.statusClass = 'deal-deposit-status--yellow';
        break;
      case Deal_Deposit_Status_Enum.Pending:
        this.statusText = 'Pending';
        this.statusClass = 'deal-deposit-status--yellow';
        break;
      case Deal_Deposit_Status_Enum.Received:
        this.statusText = 'Received';
        this.statusClass = 'deal-deposit-status--yellow';
        break;
      //
      // Orange
      //
      case Deal_Deposit_Status_Enum.InvalidInfo:
        this.statusText = 'Invalid Info';
        this.statusClass = 'deal-deposit-status--orange';
        break;
      case Deal_Deposit_Status_Enum.MissingInfo:
        this.statusText = 'Missing Info';
        this.statusClass = 'deal-deposit-status--orange';
        break;
      //
      // Green
      //
      case Deal_Deposit_Status_Enum.Approved:
        this.statusText = 'Approved';
        this.statusClass = 'deal-deposit-status--green';
        break;
      //
      // Accent
      //
      case Deal_Deposit_Status_Enum.SentToVsol:
        this.statusText = 'Sent to VSOL';
        this.statusClass = 'deal-deposit-status--accent';
        break;
      case Deal_Deposit_Status_Enum.Reassigned:
        this.statusText = 'Reassigned';
        this.statusClass = 'deal-deposit-status--accent';
        break;
      //
      // Error
      //
      case Deal_Deposit_Status_Enum.Refunded:
        this.statusText = 'Refunded';
        this.statusClass = 'deal-deposit-status--error';
        break;
      case Deal_Deposit_Status_Enum.RefundPending:
        this.statusText = 'Refund Pending';
        this.statusClass = 'deal-deposit-status--error';
        break;
      default:
        this.statusText = status;
        this.statusClass = 'deal-deposit-status--yellow';
    }
  }
}
