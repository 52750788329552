import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@skychute/auth';
import { Apollo, gql } from 'apollo-angular';
import {
  SwitchUserTeamMutation,
  SwitchUserTeamMutationVariables,
} from '../../generated/operations';
import { SwitchTeamResp } from '@skychute/schema';
import { JwtService } from '@skychute/jwt';
import { firstValueFrom } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'skychute-team-switcher-dialog',
  templateUrl: './team-switcher-dialog.component.html',
  styleUrls: ['./team-switcher-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TeamSwitcherDialogComponent implements OnInit {
  email: string;
  isLoading = false;
  errorMsg = '';

  constructor(
    private auth: AuthService,
    private jwt: JwtService,
    private apollo: Apollo,
    public dialogRef: MatDialogRef<TeamSwitcherDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { orgId: string; orgName: string; role: string },
  ) {
    this.email = this.jwt.getTokenModel().getEmail();
  }

  async ngOnInit(): Promise<void> {
    // switch team instantly without waiting for user confirmation
    // comment/remove this method, if user confirmation is required
    await this.switchTeam(this.dialogData.orgId);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  async switchTeam(teamId: string): Promise<void> {
    this.isLoading = true;
    const resp = await this.switchUserTeam(teamId);
    if (resp.success) {
      const token = resp.token;
      const refreshToken = resp.refreshToken;
      await this.auth.loginWithToken({ token, refreshToken });
    } else {
      this.errorMsg = resp.message;
      this.isLoading = false;
      return;
    }

    // reload app (go to projects page, as existing pay may not be visible by new team)
    if (document.location.hostname === 'localhost') {
      document.location.href = 'http://localhost:4200/pages/projects';
    } else {
      document.location.href = `${document.location.protocol}//${document.location.hostname}/pages/projects`;
    }
  }

  async switchUserTeam(teamId: string): Promise<SwitchTeamResp> {
    const resp = await firstValueFrom(
      this.apollo.mutate<SwitchUserTeamMutation, SwitchUserTeamMutationVariables>({
        mutation: SWITCH_USER_TEAM,
        variables: {
          teamId,
        },
      }),
    );
    return resp.data.switch_team;
  }
}

const SWITCH_USER_TEAM = gql`
  mutation switchUserTeam($teamId: uuid!) {
    switch_team(teamId: $teamId) {
      success
      message
      token
      refreshToken
    }
  }
`;
