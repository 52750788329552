import { Inject, Injectable } from '@angular/core';
import { Apollo, gql, SubscriptionResult } from 'apollo-angular';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApolloQueryResult } from '@apollo/client';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { AttachmentUploadProgressInterface } from './interfaces/attachment-upload-progress.interface';
import {
  CreateAttachmentMutation,
  CreateAttachmentMutationVariables,
  DeleteAttachmentByUrlMutation,
  DeleteAttachmentByUrlMutationVariables,
  DeleteAttachmentMutation,
  DeleteAttachmentMutationVariables,
  DeleteAttachmentProductTypeMutation,
  DeleteAttachmentProductTypeMutationVariables,
  GetAttachmentsSubscription,
  GetAttachmentsSubscriptionVariables,
  GetAttachmentUploadRequestQuery,
  GetAttachmentUploadRequestQueryVariables,
  InsertAttachmentProductTypeMutation,
  InsertAttachmentProductTypeMutationVariables,
  SubAttachmentByIdSubscription,
  SubAttachmentByIdSubscriptionVariables,
  UpdateAttachmentMutation,
  UpdateAttachmentMutationVariables,
  UpdateAttachmentOwnerMutation,
  UpdateAttachmentOwnerMutationVariables,
} from '../generated/lib/operations';
import {
  Attachment_Insert_Input,
  Attachment_Product_Type_Insert_Input,
  Attachment_Type_Enum,
} from '@skychute/schema';
import { SeedId } from '@skychute/shared-constants';
import { JwtService } from '@skychute/jwt';
import { IEnvironment } from '@skychute/shared-models';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  environment: IEnvironment;

  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private jwt: JwtService,
    @Inject('environment') environment: IEnvironment,
  ) {
    this.environment = environment;
  }

  /**
   * Uploads given file to the S3
   * Subject completes when file is uploaded and attachment record is created in the database.
   * upload is completed when "isLoading" is false or when "percents" is 100 see: AttachmentUploadProgressInterface
   * you can also wait for Subject to be completed and then use the latest value which was emitted
   * @param file
   * @param type
   */
  upload(file: File, type: Attachment_Type_Enum): Subject<AttachmentUploadProgressInterface> {
    return this.uploadAngularWay(file, type);
  }

  uploadAngularWay(
    file: File,
    type: Attachment_Type_Enum,
  ): Subject<AttachmentUploadProgressInterface> {
    const obs = new Subject<AttachmentUploadProgressInterface>();
    let key = '';
    const attachmentType = type;
    const contentType = file.type;
    let attachmentId: string;
    let originalFileName = file.name;
    originalFileName = encodeURIComponent(originalFileName);

    this._getUploadRequest(contentType, attachmentType, originalFileName)
      .pipe(
        // convert JSON to AttachmentUploadRequest
        map((resp) => {
          const requestJSON = resp.data.get_attachment_upload_request.request;
          return JSON.parse(requestJSON) as AttachmentUploadRequest;
        }),
        // get upload URL and formData to send to URL
        map((uploadRequest) => {
          // save key to use later
          key = uploadRequest.fields.key;
          attachmentId = uploadRequest.fields.id;
          return { url: uploadRequest.url, formData: this._getFormData(file, uploadRequest) };
        }),
        // start S3 file upload to URL with formData
        switchMap(({ url, formData }) => {
          return this.http.post(url, formData, {
            reportProgress: true,
            observe: 'events',
          });
        }),
      )
      .subscribe((event: HttpEvent<null>) => {
        // File was uploaded, complete subject
        if (event.type === HttpEventType.Response) {
          // simulate that download is still in progress 1% to go
          // we need to wait for new attachment record to appear in the database
          obs.next({
            isLoading: true,
            loaded: 99,
            total: 100,
            percents: 99,
            key,
            id: attachmentId,
          });

          // Subscribe to attachment with particular ID and wait for it to appear in DB
          // new attachment record will be created by AWS lambda function after a couple of seconds after successful upload
          const getByIdSub = this.getByIdSubscription(attachmentId).subscribe((resp) => {
            const downloadUrl = resp?.data?.attachment_by_pk?.download_url;
            if (!downloadUrl) {
              return;
            }
            getByIdSub.unsubscribe();

            // Send progress update
            obs.next({
              isLoading: false,
              loaded: 100,
              total: 100,
              percents: 100,
              key,
              download_url: downloadUrl,
              id: attachmentId,
            });

            // Complete Subject
            obs.complete();
            const isVideoUpload = contentType === Attachment_Type_Enum.Media;
            // update isThumbnailGenerate generation true here
            // skip update process while file uploading by cypress or seed
            // currently, generate thumbnail form video does not supported. so, skip event here
            if (!this.isFakeUpload() && !isVideoUpload) {
              // make isThumbnailGenerate for fire Hasura Event generate_thumbnail
              // generate_thumbnail generate the various thumbnail
              this.updateAttachment(attachmentId, { is_thumbnail_generate: true }).then();
            }
          });
        }

        // Upload is in progress
        // do not emit 100 percent as we need to wait for new record in attachment table to appear
        // this will be done by the backend lambda function
        if (event.type === HttpEventType.UploadProgress) {
          const percents = Math.round((event.loaded / event.total) * 100);
          obs.next({
            isLoading: true,
            loaded: event.loaded,
            total: event.total,
            percents: percents === 100 ? 99 : percents,
            key,
          });
        }
      });

    return obs;
  }

  // Check fake upload by user.
  isFakeUpload(): boolean {
    // Check attachment uploader
    // We skip this process if upload by seed and cypress
    if (this.jwt.getUserId() === SeedId.USER_DEVELOPER_SEED_OWNER_ID) {
      return true;
    }

    // detect cypress user and skip process if found.
    if (this.jwt.getTokenModel().getEmail().includes('cypress')) {
      return true;
    }

    // detect cypress user and skip process if found.
    if (this.jwt.getTokenModel().getFirstName().includes('cypress')) {
      return true;
    }

    // detect cypress user and skip process if found.
    return this.jwt.getTokenModel().getLastName().includes('cypress');
  }

  /**
   * Returns subscription to all attachments
   */
  all(): Observable<SubscriptionResult<GetAttachmentsSubscription>> {
    return this.apollo.subscribe<GetAttachmentsSubscription, GetAttachmentsSubscriptionVariables>({
      query: GET_ATTACHMENTS,
    });
  }

  /**
   * Subscribe to an attachment with given id
   * @param id
   */
  getByIdSubscription(id: string): Observable<SubscriptionResult<SubAttachmentByIdSubscription>> {
    return this.apollo.subscribe<
      SubAttachmentByIdSubscription,
      SubAttachmentByIdSubscriptionVariables
    >({
      query: SUB_ATTACHMENT_BY_ID,
      variables: {
        id,
      },
    });
  }

  /**
   * Deletes attachment with given id. S3 cleanup is handled by cloud function automatically.
   * Returns id of attachment which was removed, null otherwise
   * @param attachmentId
   */
  async delete(attachmentId: string): Promise<string | null> {
    const resp = await firstValueFrom(
      this.apollo.mutate<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>({
        mutation: DELETE_ATTACHMENT,
        variables: { attachmentId },
      }),
    );
    return resp.data.delete_attachment_by_pk?.id ?? null;
  }

  //
  /**
   * Deletes attachment with given URl. S3 cleanup is handled by cloud function automatically.
   * Returns true if attachment was removed, false otherwise
   * @param attachmentURl
   */
  async deleteByUrl(attachmentURl: string): Promise<boolean> {
    const resp = await firstValueFrom(
      this.apollo.mutate<DeleteAttachmentByUrlMutation, DeleteAttachmentByUrlMutationVariables>({
        mutation: DELETE_ATTACHMENT_BY_URL,
        variables: { attachmentUrl: attachmentURl },
      }),
    );
    return resp.data.delete_attachment.affected_rows > 1;
  }

  //
  // Private methods
  //

  /**
   * Returns request definition we can use to upload file to S3 directly
   * contains upload URL and additional fields we need to send together with file data
   *
   * Behind the scenes calls Hasura action which returns URL to upload file
   * to S3 together with additional fields we need to include with upload request
   * @private
   */
  private _getUploadRequest(
    contentType: string,
    type: string,
    fileName?: string,
  ): Observable<ApolloQueryResult<GetAttachmentUploadRequestQuery>> {
    return this.apollo.query<
      GetAttachmentUploadRequestQuery,
      GetAttachmentUploadRequestQueryVariables
    >({
      query: GET_UPLOAD_REQUEST,
      variables: {
        contentType,
        type,
        fileName,
      },
    });
  }

  /**
   * Returns FormData to be sent during file upload
   * @param file
   * @param uploadReq
   * @private
   */
  private _getFormData(file: File, uploadReq: AttachmentUploadRequest): FormData {
    // Create Form Data to send to S3
    const formData = new FormData();
    Object.keys(uploadReq.fields).forEach((key) => {
      formData.append(key, uploadReq.fields[key]);
    });

    // must be the last one
    formData.append('file', file);

    return formData;
  }

  /**
   * Creates attachment record in Hasura
   */
  async createAttachment(
    input: Attachment_Insert_Input,
  ): Promise<CreateAttachmentMutation['insert_attachment_one'] | null> {
    const resp = await firstValueFrom(
      this.apollo.mutate<CreateAttachmentMutation, CreateAttachmentMutationVariables>({
        mutation: CREATE_ATTACHMENT,
        variables: {
          input,
        },
      }),
    );
    return resp.data?.insert_attachment_one || null;
  }

  async updateAttachment(
    id: string,
    input: Attachment_Insert_Input,
  ): Promise<UpdateAttachmentMutation['update_attachment_by_pk']> {
    const resp = await firstValueFrom(
      this.apollo.mutate<UpdateAttachmentMutation, UpdateAttachmentMutationVariables>({
        mutation: UPDATE_ATTACHMENT,
        variables: {
          id,
          input,
        },
      }),
    );
    return resp.data.update_attachment_by_pk;
  }

  async updateAttachmentOwner(
    id: string,
    input: Attachment_Insert_Input,
  ): Promise<UpdateAttachmentOwnerMutation['update_attachment_by_pk']> {
    const resp = await firstValueFrom(
      this.apollo.mutate<UpdateAttachmentOwnerMutation, UpdateAttachmentOwnerMutationVariables>({
        mutation: UPDATE_ATTACHMENT_OWNER,
        variables: {
          id,
          input,
        },
      }),
    );
    return resp.data.update_attachment_by_pk;
  }

  async createAttachmentProductType(
    input: Attachment_Product_Type_Insert_Input[],
  ): Promise<InsertAttachmentProductTypeMutation['insert_attachment_product_type']> {
    const resp = await firstValueFrom(
      this.apollo.mutate<
        InsertAttachmentProductTypeMutation,
        InsertAttachmentProductTypeMutationVariables
      >({
        mutation: INSERT_ATTACHMENT_PRODUCT_TYPES,
        variables: {
          input,
        },
      }),
    );
    return resp.data?.insert_attachment_product_type;
  }

  async deleteAttachmentProductType(
    id: string,
    types: string[],
  ): Promise<DeleteAttachmentProductTypeMutation['delete_attachment_product_type']> {
    const resp = await firstValueFrom(
      this.apollo.mutate<
        DeleteAttachmentProductTypeMutation,
        DeleteAttachmentProductTypeMutationVariables
      >({
        mutation: DELETE_ATTACHMENT_PRODUCT_TYPES,
        variables: {
          id: id,
          types: types,
        },
      }),
    );
    return resp.data?.delete_attachment_product_type;
  }
}

//
// Interfaces
//

interface AttachmentUploadRequest {
  url: string;
  fields: {
    key: string;
    id: string;
    Policy: string;
    bucket: string;
    'X-Amz-Date': string;
    'X-Amz-Algorithm': string;
    'X-Amz-Signature': string;
    'X-Amz-Credential': string;
  };
}

//
// GraphQL Queries
//

const GET_UPLOAD_REQUEST = gql`
  query getAttachmentUploadRequest($contentType: String!, $type: String!, $fileName: String) {
    get_attachment_upload_request(contentType: $contentType, type: $type, fileName: $fileName) {
      request
    }
  }
`;

const GET_ATTACHMENTS = gql`
  subscription getAttachments {
    attachment(order_by: { created_at: desc }) {
      id
      type
      download_url
    }
  }
`;

const SUB_ATTACHMENT_BY_ID = gql`
  subscription subAttachmentById($id: uuid!) {
    attachment_by_pk(id: $id) {
      id
      type
      download_url
    }
  }
`;

const DELETE_ATTACHMENT = gql`
  mutation deleteAttachment($attachmentId: uuid!) {
    delete_attachment_by_pk(id: $attachmentId) {
      id
    }
  }
`;

const CREATE_ATTACHMENT = gql`
  mutation createAttachment($input: attachment_insert_input!) {
    insert_attachment_one(object: $input) {
      id
      content_type
      download_url
      created_at
    }
  }
`;

const UPDATE_ATTACHMENT = gql`
  mutation updateAttachment($id: uuid!, $input: attachment_set_input!) {
    update_attachment_by_pk(_set: $input, pk_columns: { id: $id }) {
      download_url
      id
      name
      path
      type
      content_type
      uploaded_by
      uploaded_by_team_id
      attachment_product_types {
        product_type
      }
      attachment_config {
        attachment_thumbnail_type
        width
        height
        fit
        position
      }
      thumbnails(where: { attachment_thumbnail_type: { _eq: GALLERY_CARD } }) {
        attachment_thumbnail_type
        thumbnail_url
      }
    }
  }
`;

const UPDATE_ATTACHMENT_OWNER = gql`
  mutation updateAttachmentOwner($id: uuid!, $input: attachment_set_input!) {
    update_attachment_by_pk(_set: $input, pk_columns: { id: $id }) {
      id
    }
  }
`;

const INSERT_ATTACHMENT_PRODUCT_TYPES = gql`
  mutation insertAttachmentProductType($input: [attachment_product_type_insert_input!]!) {
    insert_attachment_product_type(
      objects: $input
      on_conflict: {
        constraint: attachment_product_type_attachment_id_product_type_key
        update_columns: [product_type]
      }
    ) {
      affected_rows
      returning {
        id
        product_type
      }
    }
  }
`;

const DELETE_ATTACHMENT_PRODUCT_TYPES = gql`
  mutation deleteAttachmentProductType($id: uuid, $types: [String!]) {
    delete_attachment_product_type(
      where: { attachment_id: { _eq: $id }, product_type: { _in: $types } }
    ) {
      affected_rows
    }
  }
`;

const DELETE_ATTACHMENT_BY_URL = gql`
  mutation deleteAttachmentByUrl($attachmentUrl: String) {
    delete_attachment(where: {download_url: {_eq: $attachmentUrl}}) {
      affected_rows
    }
  }
`;
