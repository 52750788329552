// tslint:disable-next-line: no-reference
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../../../node_modules/@types/segment-analytics/index.d.ts" />
import { Inject, Injectable } from '@angular/core';
import { JwtService } from '@skychute/jwt';
import { IEnvironment, UserTraitInterface } from '@skychute/shared-models';
import { ProjectService } from './project.service';
import { SegmentEnum } from '@skychute/shared-constants';
import { DealService } from './deal.service';
import { Listing_Bool_Exp } from '@skychute/schema';
import { TeamService } from './team.service';
import { UserAppConfigService } from './user-app-config.service';
import { HubSpotTrackingService } from './hubspot-tracking.service';

/**
 * The purpose of this service is to track user events and then send them to GoogleAnalytics, FullStory, Whatever
 * ANALYTICS_ENABLED environment variable is used to enable/disable this service
 * SEGMENT_WRITE_KEY environment variable is used to specify key which will be used to identify source of events
 */

const SEGMENT_SCRIPT = `
!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="YOUR_WRITE_KEY";analytics.SNIPPET_VERSION="4.15.2";
analytics.load("YOUR_WRITE_KEY");
analytics.page();
}}();
`;

@Injectable({
  providedIn: 'root',
})
export class SegmentService {
  segmentWriteKey: string;

  // whether segment analytics is enabled or not
  isEnabled: boolean;

  // Promise which is resolved when segment analytics is loaded
  whenReady: Promise<void>;

  // used in <script id="segment-script">
  segmentScriptID = 'segment-script';

  public analytics: SegmentAnalytics.AnalyticsJS;
  private environment: IEnvironment;

  constructor(
    private jwt: JwtService,
    private hubSpotTracking: HubSpotTrackingService,
    @Inject('environment') environment: IEnvironment,
    private dealService: DealService,
    private projectService: ProjectService,
    private teamService: TeamService,
    private userAppConfigService: UserAppConfigService,
  ) {
    this.environment = environment;
    this.isEnabled = environment.services.analytics.segment.enabled;
    this.segmentWriteKey = environment.services.analytics.segment.writeKey;
    if (this.isEnabled) {
      this.whenReady = new Promise((resolve) => {
        this.injectSegmentScriptTag(() => {
          // once segment script is injected and onload function is executed we get here
          resolve();
        });
      });
    }
  }

  isSegmentScriptInjected(): boolean {
    return !!document.querySelector(`#${this.segmentScriptID}`);
  }

  /**
   * This will add a new <script> tag for Segment Analytics if it's not already present
   * Returns promise which will be resolved when all JS files of Segment Analytics are loaded
   */
  injectSegmentScriptTag(callback: () => void): void {
    try {
      if (this.isSegmentScriptInjected()) {
        return;
      }
      const segmentScriptTag = document.createElement('script');
      segmentScriptTag.setAttribute('id', this.segmentScriptID);
      segmentScriptTag.innerHTML = SEGMENT_SCRIPT.replace(/YOUR_WRITE_KEY/g, this.segmentWriteKey);
      document.body.appendChild(segmentScriptTag);
      callback();
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Shortcut for analytics.identify, will work only if segment is enabled
   * will silently stop otherwise
   * @param jwt
   */
  public async identifyUser(jwt: string): Promise<void> {
    if (!jwt || !this.isEnabled) {
      return;
    }
    try {
      const userId = this.jwt.getUserId();
      if (userId) {
        // HubSpot Tracking
        this.hubSpotTracking.identifyUser();

        // Segment Tracking
        const userInfo = this.jwt.getTokenModel();
        const traits = await this.getUserTrait();
        this.whenReady.then(() => {
          window.analytics.identify(userId, {
            email: userInfo.getEmail(),
            traits,
            displayName: `${userInfo.getFirstName()} ${userInfo.getLastName()}`,
            role: this.jwt.getDefaultRole(),
          });
        });
      }
    } catch (error) {
      console.log(
        'Unexpected error happened during user identification to the analytics service',
        error,
      );
    }
  }

  /**
   * Get User trait
   * @return UserTraitInterface || null
   */
  async getUserTrait(): Promise<UserTraitInterface> {
    try {
      let result = null;
      if (this.jwt.isAuthenticated()) {
        const teamName = await this.jwt.getTeamName();
        const userConfig = await this.userAppConfigService.get();
        const sfTeam = userConfig.salesforce?.[0]?.name ?? '';
        result = {
          userId: this.jwt.getUserId(),
          email: this.jwt.getTokenModel().getEmail(),
          firstName: this.jwt.getTokenModel().getFirstName(),
          lastName: this.jwt.getTokenModel().getLastName(),
          teamId: this.jwt.getTeamId(),
          teamType: this.jwt.getTeamType(),
          role: this.jwt.getUserRoleOfCurrentTeam(),
          teamName,
          impersonated: this.jwt.isAdminImpersonatingUser(),
          impersonatedById: null,
          impersonatedByFirstName: null,
          impersonatedByLastName: null,
          salesforceOrgName: sfTeam,
        };
        if (result.impersonated) {
          result.impersonatedById = this.jwt.getImpersonatedByTokenModel().getHasuraUserId();
          result.impersonatedByFirstName = this.jwt.getImpersonatedByTokenModel().getFirstName();
          result.impersonatedByLastName = this.jwt.getImpersonatedByTokenModel().getLastName();
        }
      }

      return result;
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Shortcut for analytics.track which will execute only if segment is enabled
   * will silently stop otherwise
   * @param name
   * @param eventData
   */
  trackEvent(name: string, eventData: any = {}): void {
    (async () => {
      try {
        if (!this.isEnabled) {
          return;
        }

        // add sales force team name if user authenticated
        if (this.jwt.isAuthenticated()) {
          const config = await this.userAppConfigService.get();
          const sfTeam = config.salesforce?.[0]?.name ?? '';
          eventData = { ...eventData, ...{ salesforceOrgName: sfTeam } };
        }

        eventData = await this.addMissingEventData(name, eventData);
        this.whenReady.then(() => {
          window.analytics.track(name, eventData);
        });
      } catch (error) {
        console.log('Unexpected error happened during track event to the analytics service', error);
      }
    })();
  }

  /**
   * Shortcut for analytics.page which will execute only if segment is enabled
   * will silently stop otherwise
   */
  page(): void {
    if (!this.isEnabled) {
      return;
    }
    try {
      // HubSpot trackPageView event
      // timeout after NavigationEnd event, so title is read correctly
      setTimeout(() => {
        this.hubSpotTracking.trackPageView(`${document.location.pathname}${document.location.search}`);
      }, 500);


      // Segment
      this.whenReady.then(() => {
        window.analytics.page();
      });
    } catch (error) {
      console.log(
        'Unexpected error happened during page navigation to the analytics service',
        error,
      );
    }
  }

  /**
   * Add missing data to event
   */
  private async addMissingEventData(
    eventName: string | SegmentEnum,
    eventData: any,
  ): Promise<unknown> {
    try {
      if (eventData?.projectId && !eventData?.projectName) {
        eventData.projectName = await this.projectService.getProjectNameById(eventData.projectId);
      }

      switch (eventName) {
        case SegmentEnum.RESERVATION_WIZARD_OPENED:
        case SegmentEnum.RESERVATION_LINK_SENT:
        case SegmentEnum.SOLICITOR_COMPANY_ADDED:
        case SegmentEnum.SOLICITOR_CONTACT_ADDED:
        case SegmentEnum.RESERVATION_WIZARD_STEP_COMPLETED:
        case SegmentEnum.RESERVATION_WIZARD_COMPLETED:
        case SegmentEnum.CONTRACT_SOLICITOR_UPDATED:
        case SegmentEnum.CONTRACT_STATUS_UPDATED:
        case SegmentEnum.CONTRACT_CANCELLED:
        case SegmentEnum.SALES_ADVICE_DOWNLOADED:
        case SegmentEnum.FILE_ATTACHED:
        case SegmentEnum.FILE_DETACHED:
        case SegmentEnum.DEPOSIT_TRANSACTION:
        case SegmentEnum.DEPOSIT_TRANSACTION_UPDATED:
        case SegmentEnum.DEPOSIT_REFUNDED:
        case SegmentEnum.DEPOSIT_RECEIVED_BY_PLATFORM:
        case SegmentEnum.DEPOSIT_TRANSFERRED:
        case SegmentEnum.CONTRACT_PAGE_OPENED: {
          const filter: Listing_Bool_Exp = {};
          if (eventData?.dealId) {
            // get detail by deal if if exist
            filter.deals = { id: { _eq: eventData?.dealId } };
          } else if (eventData?.listingId) {
            // otherwise get by listing
            filter.id = { _eq: eventData.listingId };
          }
          if (Object.keys(filter).length) {
            const response = await this.dealService.getListingDetail({ conditions: [filter] });
            if (response) {
              eventData.property = {
                label: response?.[0]?.property?.name,
                value: response?.[0]?.property?.id,
              };
              eventData.propertyGroup = {
                label: response?.[0]?.property?.property_group?.name,
                value: response?.[0]?.property?.property_group?.id,
              };
              eventData.agencyOrg = {
                label: response?.[0]?.project?.organisation?.name,
                value: response?.[0]?.project?.organisation?.id,
              };
              eventData.level = response?.[0]?.property?.level;
              eventData.listingId = response?.[0]?.id;
              if (response?.[0].deals?.[0]?.contact) {
                eventData.leadContact = {
                  label: `${response?.[0].deals?.[0]?.contact?.first_name} ${response?.[0].deals?.[0]?.contact?.last_name}`,
                  value: response?.[0].deals?.[0]?.contact?.id,
                };
              }
              if (!eventData?.dealStatus && response?.[0]?.deals?.[0]?.status) {
                eventData.dealStatus = response?.[0]?.deals?.[0]?.status;
              }
            }
          }
          break;
        }
      }

      return eventData;
    } catch (err) {
      console.error(err);
    }
  }
}
