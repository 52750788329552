import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Observable, range } from 'rxjs';
import { filter, map, toArray } from 'rxjs/operators';

@Component({
  selector: 'skychute-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() offset: number; // current offset
  @Input() limit = 100; // record per page. default limit 100
  @Input() size = 0; // total records, default 0
  @Input() range = 3;
  // hide pagination component when count is zero and this flag is true.
  // you can keep showing make this false
  @Input() autoHide = true;

  @Output() pageChange: EventEmitter<number>;
  currentPage: number;
  totalPages: number;
  pages: Observable<number[]>;

  constructor() {
    this.pageChange = new EventEmitter<number>();
  }

  ngOnInit() {
    this.getPages(this.offset, this.limit, this.size);
  }

  ngOnChanges() {
    this.getPages(this.offset, this.limit, this.size);
  }

  getCurrentPage(offset: number, limit: number): number {
    return Math.floor(offset / limit) + 1;
  }

  getTotalPages(limit: number, size: number): number {
    return Math.ceil(Math.max(size, 1) / Math.max(limit, 1));
  }

  getPages(offset: number, limit: number, size: number) {
    this.currentPage = this.getCurrentPage(offset, limit);
    this.totalPages = this.getTotalPages(limit, size);
    this.pages = range(-this.range, this.range * 2 + 1).pipe(
      map((_offset) => this.currentPage + _offset),
      filter((page) => this.isValidPageNumber(page, this.totalPages)),
      toArray(),
    );
  }

  isValidPageNumber(page: number, totalPages: number): boolean {
    return page > 0 && page <= totalPages;
  }

  selectPage(page: number, event: any) {
    event.preventDefault();
    if (this.isValidPageNumber(page, this.totalPages)) {
      this.getPages((page - 1) * this.limit, this.limit, this.size);
      this.pageChange.emit((page - 1) * this.limit);
    }
  }

  cancelEvent(event) {
    event.preventDefault();
  }
}
