import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AttachmentModel } from '@skychute/shared-models';
import { CdnService } from '@skychute/cdn';

@Component({
  selector: 'skychute-uploaded-document',
  templateUrl: './uploaded-document.component.html',
  styleUrls: ['./uploaded-document.component.scss'],
})
export class UploadedDocumentComponent {
  downloadUrl: string;
  myData: AttachmentModel;

  @Input() set data(data: AttachmentModel) {
    if (this.myData?.id !== data?.id) {
      this.myData = data;
      this.downloadUrl = this.cdn.getCdnUrl(data.download_url);
    }
  }

  @Input() isReadOnly = true;
  @Input() isLoading = false;
  @Output() deleteFile = new EventEmitter<AttachmentModel>();

  constructor(private cdn: CdnService) {}

  delete(file: AttachmentModel) {
    this.deleteFile.emit(file);
  }
}
