import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'skychute-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  logoURLx1: string;
  logoURLx2: string;

  @Input() set logoUrl(url: string | string[]) {
    if (Array.isArray(url)) {
      this.logoURLx1 = url?.[0] ?? '';
      this.logoURLx2 = url?.[1] ?? '';
    } else {
      this.logoURLx1 = url;
      this.logoURLx2 = url;
    }
  }

  @Input() text: string;
  @Input() size: 'small';
  @Input() bgColor: string;
  @Input() fgColor: string;

  /**
   * Converts string like "Barden Ridge" to "BR", "Tullimbar" to "T"
   * @param input
   */
  shortName(input: string): string {
    const parts = input.split(/\s+/);
    if (parts.length === 1) {
      return parts[0][0].toUpperCase();
    } else if (parts.length >= 2) {
      return parts[0][0].toUpperCase() + parts[1][0].toUpperCase();
    }
  }
}
