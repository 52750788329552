import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { IEnvironment } from '@skychute/shared-models';

declare let Stripe: any;

@Component({
  selector: 'skychute-stripe-payment-method',
  templateUrl: './stripe-payment-method.component.html',
  styleUrls: ['./stripe-payment-method.component.scss'],
})
export class StripePaymentMethodComponent implements AfterViewInit {
  @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef;
  @ViewChild('cardNumberEle', { static: false }) cardNumberEle: ElementRef;
  @ViewChild('cardExpiryEle', { static: false }) cardExpiryEle: ElementRef;
  @ViewChild('cardCvcEle', { static: false }) cardCvcEle: ElementRef;

  @Input() errorMessage = '';
  @Output() readyToPay = new EventEmitter<boolean>();
  cardErrorMessage = '';

  isLoading = false;
  stripePublicKey: string;
  stripe: any;

  card: any;
  cardNumber: any;
  cardExpiry: any;
  cardCvc: any;
  cardHolderName = '';

  stripeInitiated = false;
  isCardNumberValid = false;
  isCVCValid = false;
  isExpiryValid = false;
  private environment: IEnvironment;

  constructor(@Inject('environment') environment: IEnvironment) {
    this.environment = environment;
  }

  async ngAfterViewInit(): Promise<void> {
    this.stripePublicKey = this.environment.stripe?.publicKey;
    if (!this.stripeInitiated) {
      await this.initiateStripePay();
    }
  }

  injectStripeScriptIfRequired(): Promise<void> {
    return new Promise((resolve) => {
      let stripeScript = document.querySelector('#skychute-stripe') as HTMLScriptElement;
      if (stripeScript) {
        // Stripe is already injected
        resolve();
        return;
      }
      stripeScript = document.createElement('script') as HTMLScriptElement;
      stripeScript.addEventListener('load', () => {
        // Stripe script loaded, but may not yet be available as global variable
        const stripeInterval = setInterval(() => {
          if (window['Stripe'] !== undefined) {
            clearInterval(stripeInterval);
            resolve();
          }
        }, 100);
      });
      stripeScript.id = 'skychute-stripe';
      stripeScript.src = 'https://js.stripe.com/v3/';
      // Inject Stripe script
      document.body.appendChild(stripeScript);
    });
  }

  async initiateStripePay(): Promise<void> {
    this.isLoading = true;
    await this.injectStripeScriptIfRequired();
    try {
      // Set your publishable key: remember to change this to your live publishable key in production
      // See your keys here: https://dashboard.stripe.com/account/apikeys
      this.stripe = Stripe(this.stripePublicKey);
      const elements = this.stripe.elements();
      // Set up Stripe.js and Elements to use in checkout form
      const style = {
        base: {
          color: '#32325d',
          fontWeight: 500,
          fontSize: '16px',
          fontSmoothing: 'antialiased',

          '::placeholder': {
            color: '#CFD7DF',
          },
          ':-webkit-autofill': {
            color: '#e39f48',
          },
        },
        invalid: {
          color: '#E25950',

          '::placeholder': {
            color: '#FFCCA5',
          },
        },
      };

      const classes = {
        focus: 'focused',
        empty: 'empty',
        invalid: 'invalid',
      };

      this.cardNumber = elements.create('cardNumber', { showIcon: true, style, classes });
      this.cardNumber.mount(this.cardNumberEle.nativeElement);

      this.cardExpiry = elements.create('cardExpiry', { style, classes });
      this.cardExpiry.mount(this.cardExpiryEle.nativeElement);

      this.cardCvc = elements.create('cardCvc', { style, classes });
      this.cardCvc.mount(this.cardCvcEle.nativeElement);

      this.stripeInitiated = true;
      this.isLoading = false;

      this.cardNumber.addEventListener('change', (event) => {
        this.cardErrorMessage = '';
        if (event.error) {
          this.cardErrorMessage = event.error.message;
        }
        this.isCardNumberValid = event.complete;
        this.readyToPay.next(this.isValid());
      });

      this.cardCvc.addEventListener('change', (event) => {
        this.cardErrorMessage = '';
        if (event.error) {
          this.cardErrorMessage = event.error.message;
        }
        this.isCVCValid = event.complete;
        this.readyToPay.next(this.isValid());
      });

      this.cardExpiry.addEventListener('change', (event) => {
        this.cardErrorMessage = '';
        if (event.error) {
          this.cardErrorMessage = event.error.message;
        }
        this.isExpiryValid = event.complete;
        this.readyToPay.next(this.isValid());
      });
    } catch (e) {
      this.isLoading = false;
      this.errorMessage = e.message;
    }
  }

  isValid(): boolean {
    return this.stripeInitiated && this.isCardNumberValid && this.isCVCValid && this.isExpiryValid;
  }

  payNow(clientSecret: string): Promise<any> {
    if (!this.stripeInitiated) {
      throw new Error('Stripe is not initiated');
    }

    if (this.isValid()) {
      this.isLoading = true;
      return this.stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: this.cardNumber,
        },
      });
    } else {
      throw new Error('Card details are not filled properly');
    }
  }

  clearAllInputs(): void {
    this.cardExpiryEle.nativeElement.value = '';
    this.cardCvcEle.nativeElement.value = '';
    this.cardNumberEle.nativeElement.value = '';
  }
}
