<div class="screen-alert">
    <div>
        <mat-icon class="success-icon">check_circle</mat-icon>
        <h1>{{ title }}</h1>
        <ng-container *ngIf="actionText">
            <button mat-flat-button color="primary" [routerLink]="actionRouterLink" (click)="actionClick.emit()">
                {{ actionText }}
            </button>
        </ng-container>
    </div>
</div>
