import { Component, Inject, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'skychute-screen-loader',
  templateUrl: './screen-loader.component.html',
  styleUrls: ['./screen-loader.component.scss'],
})
export class ScreenLoaderComponent {
  @Input() title = '';
  @Input() content: SafeHtml | string = '';

  constructor(
    private domSanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA)
    public data: { title: string; content: SafeHtml | string },
  ) {
    this.title = data.title;
    if (typeof data.content === 'string') {
      this.content = this.domSanitizer.bypassSecurityTrustHtml(data.content);
    }
  }
}
