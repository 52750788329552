import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts amount into Aus currency without decimal
 * example: {{listing.price | ausCurrencyWithoutDecimal}} => '$999'
 */
@Pipe({
  name: 'ausCurrencyWithoutDecimal',
})
export class AusCurrencyWithoutDecimalPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: number | undefined): string {
    if (!value) {
      return '$0';
    }

    return this.currencyPipe.transform(value.toFixed(), 'AUD', 'symbol-narrow', '1.0-0');
  }
}
