import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByAttach',
  pure: false,
})
export class SortByAttachPipe implements PipeTransform {
  transform(
    value: Array<{ name: string; id: string }>,
    ids: string[],
  ): Array<{ name: string; id: string }> {
    if (value !== undefined) {
      return value.sort((firstProp: { name: string; id: string }) => {
        if (ids.includes(firstProp.id)) {
          return -1;
        }
        return 1;
      });
    }

    return value;
  }
}
