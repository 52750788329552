import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts number to area string
 * example: {{listing.totalArea | area}} => '23.3 m²'
 */
@Pipe({
  name: 'area',
})
export class AreaPipe implements PipeTransform {
  transform(value: string | number | undefined): string {
    if (typeof value === 'string') {
      value = Number(value);
    }
    if (typeof value === 'number' && !isNaN(value)) {
      const decimalPipe = new DecimalPipe('en-AU');
      const decimalNumber = decimalPipe.transform(value, '1.0-1');

      return `${decimalNumber} m²`;
    } else {
      return '—';
    }
  }
}
