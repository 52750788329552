import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'userFromEmail' })
export class UserFromEmailPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }
    return value.split('@')[0];
  }
}
