import { Component, OnDestroy } from '@angular/core';
import { LoaderService } from '../../loader.service';
import { ReplaySubject, takeUntil } from 'rxjs';

@Component({
  selector: 'skychute-logo-loader',
  templateUrl: './logo-loader.component.html',
  styleUrls: ['./logo-loader.component.scss'],
})
export class LogoLoaderComponent implements OnDestroy {
  protected destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  text = '';

  constructor() {
    document.body.classList.add('hide-scroll');
    LoaderService.loadingText.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.text = value;
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    document.body.classList.remove('hide-scroll');
  }
}
