import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts "Cocktail Launch" to 'cocktail-launch'
 * Usage: {{str | lowdash}}
 */
@Pipe({
  name: 'lowdash',
})
export class LowDashPipe implements PipeTransform {
  transform(value: string | null | boolean | undefined): string {
    if (value === undefined) {
      value = 'undefined';
    } else if (value === null) {
      value = 'null';
    } else if (typeof value === 'boolean') {
      value = value ? 'true' : 'false';
    }
    if (typeof value !== 'string') {
      throw new Error('Value supplied to |lowdash pipe is not a string');
    }
    return value.replace(/\s+/gi, '-').toLowerCase();
  }
}
