import { Component, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'skychute-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnDestroy {
  isShowLoader = true;
  @Input() text: string;

  @Input()
  set isLoading(value) {
    this.isShowLoader = value;
    if (value) {
      document.body.classList.add('hide-scroll');
    } else {
      document.body.classList.remove('hide-scroll');
    }
  }

  ngOnDestroy(): void {
    if (document.body.classList.contains('hide-scroll')) {
      document.body.classList.remove('hide-scroll');
    }
  }
}
