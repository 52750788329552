import { Directive, ElementRef, Input, NgZone, OnChanges } from '@angular/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

@Directive({
  selector: '[skychuteInsufficientInfiniteScroll]',
  exportAs: 'insufficientInfiniteScroll',
})
export class InsufficientInfiniteScrollDirective
  extends InfiniteScrollDirective
  implements OnChanges
{
  @Input() loadedItems: number;
  _element: ElementRef;
  previousLoadedItems: number;

  constructor(element: ElementRef, zone: NgZone) {
    super(element, zone);
    this._element = element;
  }

  ngOnChanges(): void {
    const htmlElement = this._element.nativeElement as HTMLElement;
    if (
      this.loadedItems > 0 &&
      this.loadedItems !== this.previousLoadedItems &&
      htmlElement.clientHeight === htmlElement.scrollHeight
    ) {
      this.previousLoadedItems = this.loadedItems;
      this.scrolled.emit();
    }
  }
}
