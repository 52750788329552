import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts "2000000" to '$2M+'
 * "350000" to "$350K+"
 * Usage: {{item.price | shortPrice}}
 */
@Pipe({
  name: 'shortPrice',
})
export class ShortPricePipe implements PipeTransform {
  transform(price: number): string {
    if (!price) {
      return '';
    }
    if (typeof price === 'string') {
      price = parseInt(price, 10);
      if (isNaN(price)) {
        throw new Error(`ShortPricePipe is unable to convert: ${price} into a number`);
      }
    }
    if (typeof price !== 'number') {
      throw new Error(`ShortPricePipe works with numbers/strings only. Got: ${price}`);
    }

    let value = '';
    let postfix = 'K';
    const oneBillion = 1000000000;
    const oneMillion = 1000000;
    const oneK = 1000;
    if (price > oneBillion) {
      postfix = 'B';
      value = `${Math.round(price / oneBillion)}`;
    } else if (price > oneMillion) {
      postfix = 'M';
      value = `${Math.round(price / oneMillion)}`;
    } else if (price > oneK) {
      postfix = 'K';
      value = `${Math.round(price / oneK)}`;
    }
    return `$${value}${postfix}+`;
  }
}
