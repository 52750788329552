import { Injectable } from '@angular/core';
import { UserAppConfigService } from '@skychute/shared-services';

@Injectable({
  providedIn: 'root',
})
export class CdnService {
  constructor(private appConfig: UserAppConfigService) {}

  getCdnUrl(srcUrl: string): string {
    // Unable to convert to CDN URL
    if (!srcUrl) {
      return srcUrl;
    }

    // Some strange non string value
    if (typeof srcUrl !== 'string') {
      return srcUrl;
    }

    // if URL belongs to YouTube
    if (srcUrl.includes('youtube')) {
      return srcUrl;
    }

    // Non CDN supported URL
    // Example: https://skychute-resources.s3-ap-southeast-2.amazonaws.com/connect-portal-seed-images/dahua-demo/b5969570-45dd-43d8-8733-816c87ff8cba.jpg
    if (srcUrl.match(/skychute-resources/gi)) {
      return srcUrl;
    }

    // URL is not from attachment bucket
    if (!srcUrl.match(/attachment-bucket/gi)) {
      return srcUrl;
    }

    // URL from SharpSky image resizing lambda, can't be served via CDN
    if (srcUrl.match(/sharp-sky/gi)) {
      return srcUrl;
    }

    const config = this.appConfig.getNonAsync();

    // Already a CDN URL
    if (srcUrl.indexOf(config.cdn) !== -1) {
      return srcUrl;
    }

    // CDN disabled, return original URL
    if (!config.cdn) {
      return srcUrl;
    }

    // CDN enabled

    // Production have a bit different bucket name than feature branches
    // prod: https://s3.ap-southeast-2.amazonaws.com/production-attachment-bucket/attachments/thumbnails/fa9e4d25-c9e7-43aa-970b-0f9f3f849a70.png
    const prodBucket = 'https://s3.ap-southeast-2.amazonaws.com/production-attachment-bucket';
    if (srcUrl.indexOf(prodBucket) !== -1) {
      return srcUrl.replace(prodBucket, config.cdn);
    }

    const url = new URL(srcUrl);
    return srcUrl.replace(url.origin, config.cdn);
  }
}
