export enum SegmentEnum {
  CLIENT_LIST_FILTERED = 'Client List Filtered',
  CLIENT_LIST_FILTER_CLEARED = 'Client List Filter Cleared',
  CLIENT_LIST_PAGE_LOADED = 'Client List Page loaded',
  CLIENT_LIST_ITEM_SELECTED = 'Client List Item Selected',
  CLIENT_CREATION_INITIATED = 'Client Creation Initiated',
  CLIENT_LIST_SORTED = 'Client List Sorted',
  CLIENT_DETAILS_OPENED = 'Client Details Opened',
  CLIENT_DETAILS_CLOSED = 'Client Details Closed',
  CLIENT_DETAILS_TAB_VIEWED = 'Client Details Tab Viewed',
  CLIENT_DETAILS_UPDATED = 'Client Details Updated',

  AUTHENTICATION_ATTEMPTED = 'Authentication Attempted',
  USER_LOGGED_OUT = 'User Logged Out',
  PASSWORD_RESET_REQUESTED = 'Password Reset Requested',
  PASSWORD_RESET_ATTEMPTED = 'Password Reset Attempted',
  REGISTRATION_ATTEMPTED = 'Registration Attempted',

  BUILD_INFO_DIALOGUE_VIEWED = 'Build Info Dialogue Viewed',

  COMMISSION_LIST_FILTERED = 'Commission List Filtered',
  COMMISSION_LIST_SORTED = 'Commission List Sorted',
  COMMISSION_PAGE_OPENED = 'Commission Page Opened',
  COMMISSION_LIST_FILTER_CLEARED = 'Commission List Filter Cleared',
  COMMISSION_LIST_PAGE_LOADED = 'Commission List Page loaded',
  COMMISSION_LIST_ITEM_SELECTED = 'Commission List Item Selected',

  LISTING_PLAN_VIEWED = 'Listing Plan Viewed',
  LISTING_PAGE_VIEWED = 'Listing Page Viewed',
  LISTING_RESERVATION_STARTED = 'Listing Reservation Started',
  LISTING_HOLD_STARTED = 'Listing Hold Started',
  LISTING_HOLD_RELEASED = 'Listing Hold Released',
  LISTING_IMAGE_VIEWED = 'Listing Image Viewed',
  FLOOR_PLANS_EDITOR_SELECTED = 'Floor Plans Editor Selected',

  PRICE_LIST_SORTED = 'Price List Sorted',
  PRICE_LIST_FILTERED = 'Price List Filtered',
  PRICE_LIST_ITEMS_DOWNLOADED = 'Price List Items Downloaded',
  PRICE_LIST_ITEMS_SELECTED = 'Price List Item Selected',
  PRICE_LIST_FILTER_CLEARED = 'Price List Filter Cleared',
  PRICE_LIST_PAGE_LOADED = 'Price List Page loaded',
  PRICE_LIST_ITEM_CHECKED = 'Price List Item Checked',
  PRICE_LIST_TAB_VIEWED = 'Price List Tab Viewed',
  PRICE_LIST_OPEN_PROPERTY_ALLOCATION = 'Price List Open Allocation',
  PRICE_LIST_EDIT = 'Price List Edit',
  EDITOR_SELECTED = 'Editor Selected',
  PRICE_LIST_UPDATE = 'Price List Update',
  PRICE_LIST_UPDATE_ALLOCATION = 'Price List Update Allocation',

  PROJECT_PAGE_VIEWED = 'Project Page Viewed',
  PROJECT_PAGE_TAB_SELECTED = 'Project Page Tab Selected',
  PROJECT_PLAN_SELECTED = 'Project Plans Selected',
  PROJECT_LIST_VIEWED = 'Project List Viewed',
  PROJECT_CREATION_INITIATED = 'Project Creation Initiated',

  CONTRACT_PAGE_OPENED = 'Contract Page Opened',
  CONTRACT_SOLICITOR_UPDATED = 'Contract Solicitor Updated',
  CONTRACT_STATUS_UPDATED = 'Contract Status Updated',
  CONTRACT_CANCELLED = 'Contract Cancelled',
  SALES_ADVICE_DOWNLOADED = 'Sales Advice Downloaded',
  FILE_ATTACHED = 'File Attached',
  FILE_DETACHED = 'File Detached',
  FILE_REMOVED = 'File Removed',
  FILE_UPLOADED = 'File Uploaded',

  DEPOSIT_TRANSACTION = 'Deposit Transaction',
  DEPOSIT_TRANSACTION_UPDATED = 'Deposit Transaction Updated',
  DEPOSIT_RECEIVED_BY_PLATFORM = 'Deposit Received By Platform',
  DEPOSIT_REFUNDED = 'Deposit Refunded',
  DEPOSIT_TRANSFERRED = 'Deposit Transferred',

  TEAM_SETTINGS_PAGE_OPENED = 'Team Settings Page Opened',
  NEW_MEMBER_INVITED = 'New Member Invited',
  MEMBER_ROLE_UPDATED = 'Member Role Updated',
  TEAM_NAME_UPDATED = 'Team Name Updated',
  TEAM_LOGO_CHANGED = 'Team Logo Changed',
  TEAM_FAVICON_CHANGED = 'Team Favicon Changed',

  EMBEDS_PAGE_OPEN = 'Embeds Page Open',
  EMBEDS_LIST_LOADED = 'Embeds List Loaded',
  NEW_EMBED_INITIATED = 'New Embed Initiated',
  EMBED_DELETED = 'Embed Deleted',
  EMBED_EDIT = 'Embed Edited',
  EMBED_CREATED = 'New Embed Created',
  EMBED_UPDATED = 'Embed Updated',
  EMBED_CODE_COPIED = 'Embed Code Copied',
  EMBED_PREVIEW_OPENED = 'Embed Preview Opened',

  DEAL_STATUS_CHANGED = 'Deal Status Changed',

  RESERVATION_WIZARD_OPENED = 'Reservation Wizard Opened',
  RESERVATION_WIZARD_STEP_COMPLETED = 'Reservation Wizard Step Completed',
  RESERVATION_LINK_SENT = 'Reservation Link Sent',
  RESERVATION_WIZARD_COMPLETED = 'Reservation Wizard Completed',
  SOLICITOR_COMPANY_ADDED = 'Solicitor Company Added',
  SOLICITOR_CONTACT_ADDED = 'Solicitor Contact Added',

  APARTMENT_PLAN_VIEWED = 'Apartment Plan explorer Viewed',
  APARTMENT_PLAN_PRINT_VIEWED = 'Apartment Plan Print Viewed',
  APARTMENT_PLAN_PROJECT_SELECTED = 'Apartment Plan explorer Project Selected',
  APARTMENT_PLAN_BUILDING_SELECTED = 'Apartment Plan explorer Building Selected',
  APARTMENT_PLAN_LEVEL_SELECTED = 'Apartment Plan explorer Level Selected',
  APARTMENT_PLAN_PROPERTY_SELECTED = 'Apartment Plan explorer Property Selected',

  LAND_PLAN_VIEWED = 'Land Plan explorer Viewed',
  LAND_PLAN_PROJECT_SELECTED = 'Land Plan explorer Project Selected',
  LAND_PLAN_STAGE_SELECTED = 'Land Plan explorer Stage Selected',
  LAND_PLAN_SUBSTAGE_SELECTED = 'Land Plan explorer Sub Stage Selected',
  LAND_PLAN_PROPERTY_SELECTED = 'Land Plan explorer Property Selected',
  LAND_PLAN_FILTERED = 'Land Plan Explorer Filtered',
  LAND_PLAN_FILTER_CLEARED = 'Land Plan Explorer Filter Cleared',

  APARTMENT_PLAN_EDITOR_VIEWED = 'Apartment Plans Editor Opened',
  APARTMENT_PLAN_EDITOR_NAVIGATED = 'Apartment Plans Editor Navigated',
  APARTMENT_PLAN_EDITOR = 'Apartment Plans Editor ',
  APARTMENT_PLAN_EDITOR_NAVIGATED_BACK = 'Apartment Plans Editor Navigated Back',
  APARTMENT_PLAN_MASTER_PLAN = 'Apartment Plan Master plan ',

  LAND_PLAN_EDITOR_OPENED = 'Land Plans Editor Opened',
  LAND_PLAN_EDITOR_NAVIGATED = 'Land Plans Editor Navigated',
  LAND_PLAN_POLYGON = 'Lands Plan Polygon ',

  PROJECT_PLAN_EDITOR_SELECTED = 'Project Plans Editor Selected',

  PARTNER_LIST_PAGE_LOADED = 'Partner List Page loaded',

  PROJECT_NETWORK_LIST_PAGE_LOADED = 'Project Network List Page Loaded',
}
