import { Component, Input } from '@angular/core';

@Component({
  selector: 'skychute-bpay',
  templateUrl: './bpay.component.html',
  styleUrls: ['./bpay.component.scss'],
})
export class BpayComponent {
  @Input() billerCode = '817726'; // must be string
  @Input() refNumber = '2767107'; // must be string, we may have leading zeroes here
}
