import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts "Release 10 " to 'release-10'
 * Usage: {{item.name | slug}}
 */
@Pipe({ name: 'slug' })
export class SlugPipe implements PipeTransform {
  transform(input: any): string {
    if (!input) {
      return input;
    }
    let safeInput = '';

    if (typeof input !== 'string') {
      safeInput = String(input);
    } else {
      safeInput = input;
    }

    return safeInput
      .toString()
      .toLowerCase()
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w-]+/g, '-') // replace all non-word chars with -
      .replace(/--+/g, '-'); // Replace multiple - with single -
  }
}
