import { Inject, Injectable } from '@angular/core';
import { getWebAppUrl, IEnvironment } from '@skychute/shared-models';

@Injectable({
  providedIn: 'root',
})
export class CssService {
  environment: IEnvironment;

  constructor(@Inject('environment') environment: IEnvironment) {
    this.environment = environment;
  }

  setCssVars(): void {
    let webAppUrl = '';
    if (this.environment?.application?.branch) {
      webAppUrl = getWebAppUrl(this.environment.application.branch);
    }
    if (!webAppUrl) {
      return;
    }
    document.documentElement.style.setProperty(
      '--apartment-plan-editor-bg',
      `${webAppUrl}/assets/apartment-plan-editor/apartment-plan-editor-bg.png`,
    );
  }
}
