import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { LabelType, NumericRange, PriceRange } from '@skychute/shared-constants';

@Component({
  selector: 'skychute-numeric-range',
  templateUrl: './numeric-range.component.html',
  styleUrls: ['./numeric-range.component.scss'],
})
export class NumericRangeComponent implements OnInit {
  private rangeValues: number[] = [];

  @Input() range: NumericRange = new PriceRange();
  @Input() min = 0;
  @Input() max = 0;
  @Input() label = '';
  @Output() minChange = new EventEmitter<number>();
  @Output() maxChange = new EventEmitter<number>();
  @Output() valueChanged = new EventEmitter();

  ngOnInit(): void {
    this.rangeValues = this.range.getRange();
  }

  getMinRange(): number[] {
    return this.rangeValues.filter((o) => this.max === 0 || o <= this.max);
  }

  getMaxRange(): number[] {
    return this.rangeValues.filter((o) => this.min === 0 || o >= this.min);
  }
  getMinDisplayValue(value: number): string {
    if (this.range.translate) {
      return this.range.translate(value, LabelType.Min);
    }

    return value.toString();
  }

  getMaxDisplayValue(value: number): string {
    if (this.range.translate) {
      return this.range.translate(value, LabelType.Max);
    }

    return value.toString();
  }

  onMinChange(): void {
    this.minChange.emit(this.min);
    this.valueChanged.emit();
  }

  onMaxChange(): void {
    this.maxChange.emit(this.max);
    this.valueChanged.emit();
  }
}
